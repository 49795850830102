import React, { useEffect, useState } from "react";
import "./style.css";
import { AiOutlineCaretRight } from "react-icons/ai";
import { ALL_CATEGORY } from "../../../graphql/Admin/category";
import { client } from "../../../apollo";
import { UPDATE_JOB } from "../../../graphql/User/job";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useParams } from "react-router-dom";

const Category = () => {
  let { categoryid } = useParams();
  const [search_name, setsearch_name] = useState("");
  const [allcategory_data, setallcategory_data] = useState([]);
  const [category_data, setcategory_data] = useState([]);
  const [category_id, setcategory_id] = useState(categoryid);
  const [locationid, setlocationid] = useState(null);
  const [selectedtype, setselectedtype] = useState("category");
  const [searchdata, setsearchdata] = useState([]);
  const searchname = (e) => {
    setsearch_name(e.target.value);
    if (e.target.value.length !== 0) {
      var searchfilter = [];
      for (var i = 0; i < allcategory_data.length; i++) {
        if (
          allcategory_data[i].category_name
            .toLowerCase()
            .match(e.target.value.toLowerCase())
        ) {
          searchfilter.push(allcategory_data[i]);
        }
      }
      setsearchdata(searchfilter);
    }
  };

  useEffect(() => {
    getallcategory();
  }, []);
  const getallcategory = async () => {
    await client
      .query({
        query: ALL_CATEGORY,
        variables: {
          parent: "",
          limit: 20,
          page: 1,
          location_code: "IN",
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        setcategory_data(
          result.data.get_main_category_pagination_by_location.data
        );
        setallcategory_data(
          result.data.get_main_category_pagination_by_location.data
        );
      });
  };
  const searchBtn = () => {
    if (search_name.length !== 0) {
      var filterdata = [];
      for (var i = 0; i < allcategory_data.length; i++) {
        if (
          allcategory_data[i].category_name
            .toLowerCase()
            .match(search_name.toLowerCase())
        ) {
          filterdata.push(allcategory_data[i]);
        }
      }
      setcategory_data(filterdata);
    } else {
      setcategory_data(allcategory_data);
    }
  };

  const selectcategory = async(e) => {
    setcategory_id(e.target.id);
  };
  // useEffect(() => {
  //   nextbtn();
  // }, [category_id]);
  const nextbtn = async () => {
    var checkdata = allcategory_data.filter((data) => {
      return data._id == category_id;
    });
    if (checkdata.length !== 0) {
      setlocationid(checkdata[0].category_location._id);
      if (checkdata[0].is_next_child === true) {
        await client
          .query({
            query: ALL_CATEGORY,
            variables: {
              parent: checkdata[0]._id,
              limit: 20,
              page: 1,
              location_code: "IN",
            },
            fetchPolicy: "no-cache",
          })
          .then((result) => {
            setallcategory_data(
              result.data.get_main_category_pagination_by_location.data
            );
            setcategory_data(
              result.data.get_main_category_pagination_by_location.data
            );
            if (selectedtype === "category") {
              setselectedtype("SubCate");
            } else if (selectedtype === "SubCate") {
              setselectedtype("service");
            } else {
              setselectedtype("category");
            }
          });
        document.getElementById("searchinput").value = "";
      } else {
        var providerdata = localStorage.getItem("user");
        var userid = JSON.parse(providerdata)._id;
         var locationid = sessionStorage.getItem("locationid");
        var selected_address = sessionStorage.getItem("selected_address");
        var providerdata = localStorage.getItem("user");
        var userid = JSON.parse(providerdata)._id;
        await client
        .query({
          query: UPDATE_JOB,
          variables: {
            ondemand_data: [
              {
                category_id: category_id,
                address_id: selected_address,
                category_location_id: locationid,
                user_id: userid,
              },
            ],
          },
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          if (
            result.data.Update_OnDemand.status === "success"
          ) {
            localStorage.setItem("job_id", result.data.Update_OnDemand._id);
            window.location.replace(`/project/${result.data.Update_OnDemand._id}`);
          }
        });
      }
    }
  };
  const cancelbtn = async () => {
    window.location.reload();
  };
  const setsearch_nameinput = (name) => {
    document.getElementById("searchinput").value = "";
    document.getElementById("searchinput").value = name;
    setsearch_name(name);
    setsearchdata([]);
    searchBtn()
  };
  return (
    <>
      <div id="myDIV">
        <div className="ant-card bg-transparent h-100 ant-card-hoverable sidebardiv">
          <div>
            <div onClick={() => {
              window.location.replace("/project");
            }} className="d-flex f_26 sidebarheading">
              <div>Project Location</div>
            </div>
            <div className="d-flex f_26 sidebarheading livelick">
              <div>Project Category</div>
            </div>
            <div className="d-flex f_26 sidebarheading">
              <div>Review & Submit</div>
            </div>
          </div>
        </div>
        <div className="backbtndiv">
          <h6
            onClick={() => {
              window.location.replace("/project");
            }}
          >
            <IoMdArrowRoundBack
              className="backbtn"
              onClick={() => {
                window.location.replace("/project");
              }}
            />
            <b className="backname">Back</b>
          </h6>
          <div className="sidebardivnew">
            <div className="select_catdiv">
              <>
                {selectedtype === "category" ? (
                  <h2 className="newheadingclass">Select Category</h2>
                ) : selectedtype === "SubCate" ? (
                  <h2 className="newheadingclass">Select Sub Category</h2>
                ) : (
                  <h2 className="newheadingclass">Select Service</h2>
                )}

                <div>
                  <div className="nosubmit nosubmitdiv">
                    <input
                      className="nosubmit"
                      type="search"
                      placeholder="Search Category..."
                      onChange={searchname}
                      defaultValue={search_name}
                      id="searchinput"
                    />
                    <button className="search_btn" onClick={searchBtn}>
                      Search
                    </button>
                  </div>
                  {searchdata.length !== 0 ? (
                    <div className="nosubmit sidebardivnew p-2">
                      {searchdata.map((data, index) => (
                        <h1
                          className="searchnamelist"
                          key={index}
                          onClick={() => {
                            setsearch_nameinput(data.category_name);
                          }}
                        >
                          {data.category_name}
                        </h1>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="category_list">
                  {" "}
                  {category_data.length !== 0
                    ? category_data.map((data, index) => (
                        <div
                          className={
                            category_id === data._id
                              ? "cate_name selected_category"
                              : "cate_name"
                          }
                          id={data._id}
                          key={index}
                          onClick={selectcategory}
                        >
                          <div id={data._id}>
                            {category_id === data._id ? (
                              <input
                                type="radio"
                                id={data._id}
                                name="fav_language"
                                value={data._id}
                                checked
                              />
                            ) : (
                              <input
                                type="radio"
                                id={data._id}
                                name="fav_language"
                                value={data._id}
                              />
                            )}

                            <label
                              className="cat_label"
                              id={data._id}
                              htmlFor={data._id}
                            >
                              {data.category_name}
                            </label>
                          </div>
                          {/* {data.is_next_child === true ? (
                          <AiOutlineCaretRight id={data._id} />
                        ) : null} */}
                        </div>
                      ))
                    : null}
                </div>
              </>
              <div className="btn_grp">
                <button className="cancel_btn" onClick={cancelbtn}>
                  CANCEL
                </button>
                <button className="next_btn" onClick={nextbtn}>
                  NEXT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
