import React, { Suspense, useState } from "react";
import { Modal, Skeleton } from 'antd';
import { HomeContext } from '../../context/Location'
import useReactRouter from 'use-react-router';
import HomeBanner from './Banner/HomeBanner'
import HomeViewHoc from './HomeViewHoc';
// const HomeBanner = React.lazy(() => import('./Banner/HomeBanner'));
const HomeTranding = React.lazy(() => import('./Trending/HomeTranding'));
const OutdoorPage = React.lazy(() => import('./OutDoor/OutdoorPage'));
const How = React.lazy(() => import('./How/How'));
const ReadyPage = React.lazy(() => import('./DownloadPage/ReadyPage'));
const DownloadPage = React.lazy(() => import('./DownloadPage/DownloadPage'));
const ChoosePage = React.lazy(() => import('./DownloadPage/ChoosePage'));
const ChooseJobCategory = React.lazy(() => import('./ChooseJobCategory'));

const HomePage = () => {
    const [VisibleChooseCategory, SetVisibleChooseCategory] = useState(false)
    const [current_type, set_current_type] = useState("")
    const [home_page_city, set_home_page_city] = useState("Kenya")
    const [center, set_center] = useState([9.9252, 78.1198])
    const [current_id, set_current_id] = useState("")
    const [comman_data, set_comman_data] = useState({})
    const { history } = useReactRouter();

    const set_home_page_location = async (data) => {
        set_home_page_city(data?.home_page_city)
        set_center(data?.center)
    }
    const on_book = async (data) => {
        console.log(home_page_city)
        history.push('/coming_soon');
        return false
        if (localStorage.getItem('userLogin') === 'success') {
            let local_data = {
                pathname: `/description/${data._id}`,
                state: {
                    type: data.category_type,
                    location: center,
                    location_detail: home_page_city
                }
            }
            set_comman_data(local_data)
            set_current_id(data._id)
            set_current_type("trending")
            SetVisibleChooseCategory(!VisibleChooseCategory)
        } else {
            history.push('/login');
        }
    }

    const LargeSquareViewHoc = HomeViewHoc(HomeTranding, { show_all_service:true,view_type: "cricle_slider" }); //square_slider cricle_slider
    const SmallSquareViewHoc = HomeViewHoc(HomeTranding, { view_type: "square_slider" });

    return (
        <>
            <HomeContext.Provider value={{ set_home_page_location, on_book }}>
                <HomeBanner />
                <Suspense fallback={<p className="container mt-2" style={{ backgroundColor: "#eae5e5", width: '100%', height: "30px" }}></p>}>
                    <LargeSquareViewHoc />
                </Suspense>

                <Suspense fallback={<p className="container mt-2" style={{ backgroundColor: "#eae5e5", width: '100%', height: "30px" }}></p>}>
                    <How />
                </Suspense>

                <Suspense fallback={<p className="container mt-2" style={{ backgroundColor: "#eae5e5", width: '100%', height: "30px" }}></p>}>
                    <SmallSquareViewHoc />
                </Suspense>

                <Suspense fallback={<p className="container mt-2" style={{ backgroundColor: "#eae5e5", width: '100%', height: "30px" }}></p>}>
                    <ReadyPage />
                </Suspense>

                <Suspense fallback={<p className="container mt-2" style={{ backgroundColor: "#eae5e5", width: '100%', height: "30px" }}></p>}>
                    <DownloadPage />
                </Suspense>

                <Suspense fallback={<p className="container mt-2" style={{ backgroundColor: "#eae5e5", width: '100%', height: "30px" }}></p>}>
                    <ChoosePage />
                </Suspense>

                <Modal footer={null} centered visible={VisibleChooseCategory} onCancel={() => { SetVisibleChooseCategory(false) }}>
                    <Suspense fallback={<Skeleton active />}>
                        <ChooseJobCategory current_id={current_id} comman_data={comman_data} />
                    </Suspense>
                </Modal>
            </HomeContext.Provider>
        </>
    )
}
export default HomePage;
