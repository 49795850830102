import gql from "graphql-tag";
export const GET_VIEW_TAGS = gql`
query GETVIEWTAGS($search_value:String) {
    get_view_tags (search_value:$search_value){
        name
        _id
    }
}
`;
export const GET_SECTIONS = gql`
query GETSECTIONS($view_device:String,$theme_id:ID) {
    get_sections(view_device:$view_device,theme_id:$theme_id) {
    _id
    title
    view_type
    view_tag
    theme_id 
  }
}
`;

export const GET_SECTION = gql`
query GET_SECTION($_id:ID) {
    get_section(_id:$_id) {
    _id
    title
    view_type
    view_tag
    view_count
    view_device
  }
}
`;

export const UPDATE_SECTION = gql`
    mutation UPDATESECTION($_id:ID,$data:JSON){
        update_section(_id:$_id,data:$data){
            status
            msg
        }
    }
`;
export const UPDATE_VIEW_TAGS = gql`
    mutation UPDATEVIEW_TAGS($_id:ID,$name:String,$description:String){
        update_view_tag(_id:$_id,name:$name,description:$description){
            status
            msg
            _id
            name
        }
    }
`;

export const DELETE_VIEW_TAG = gql`
    mutation DELETEVIEWTAG($_id:ID){
        delete_view_tag(_id:$_id){
            status
            msg
        }
    }
`;


export const UPDATE_SECTION_ORDER = gql`
    mutation UPDATESECTIONORDER($data:[ID]){
        update_section_order(data:$data){
            status
            msg
        }
    }
`;
export const DELETE_SECTIONS = gql`
    mutation DELETESECTIONS($_id:ID){
        delete_section(_id:$_id){
            status
            msg
        }
    }
`;

export const GET_WEB_HOME_SECTIONS = gql`
query GETWEBHOMESECTIONS($view_device:String,$view_type:String,$country_code:String) {
    get_sections(view_device:$view_device,view_type:$view_type,country_code:$country_code) {
        _id
        title
        view_type
        theme_id
        country_code
        get_section_category(location_code: $country_code) {
            category_name
            _id
            get_catgeory_images(root_parent_id: true) {
                small_image
                large_image
            }
        }
    }
}
`;