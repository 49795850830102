import React, { useEffect, useState } from "react";
import jobimg from "../../../image/for_design_only/fitness_trainer.png";
import ServiceProvider from "./ServiceProvider";
import { useParams } from "react-router-dom";
import { SINGLE_JOB } from "../../../graphql/User/job";
import { client } from "../../../apollo";

const Providers = () => {
  let { jobid } = useParams();
  const [jobdetails, setjobdetails] = useState([]);
  const [ctaegoryid, setctaegoryid] = useState(null);
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    await client
      .query({
        query: SINGLE_JOB,
        variables: {
          _id: jobid,
          qid: "",
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        setjobdetails(result.data.get_ondemand_jobs);
        setctaegoryid(result.data.get_ondemand_jobs[0].category_id);
      });
  };
  return (
    <div className="container">
      {/* <img src={jobimg} alt="" className="jobviewimg mt-5" />
      {jobdetails.length !== 0 ? (
        <div className="location_div mt-3">
          <h4>
            <b>{jobdetails[0].get_category_names.category_name}</b>
          </h4>
          <h6 className="f_21">
            Location : {jobdetails[0].get_address_detail.address}
          </h6>
        </div>
      ) : null}
      <div className="location_div mt-5">
        <h4>
          <b>Job Details</b>
        </h4>
      </div>
      {jobdetails.length !== 0
        ? jobdetails[0].get_ondemand_jobs_category_question.map((data, index) =>
            data.option_type === "radio" || data.option_type === "checkbox" ? (
              <div className="reviewgroup_grp" key={index}>
                <div className="mt-3">
                  <h4 className="bold quicksand_font_bold f_26">
                    {data.question}
                  </h4>
                  {data.multiple_option.map((datanew, indexnew) =>
                    datanew.is_select === true ? (
                      <p className="f_21" key={indexnew}>
                        {datanew.label}
                      </p>
                    ) : null
                  )}
                </div>
              </div>
            ) : data.option_type === "textbox" ? (
              <div className="reviewgroup_grp" key={index}>
                <div className="mt-3">
                  <h4 className="bold quicksand_font_bold f_26">
                    {data.question}
                  </h4>
                  <p className="f_21">{data.answer}</p>
                </div>
              </div>
            ) : null
          )
        : null} */}

      <ServiceProvider categoryid={ctaegoryid} />
    </div>
  );
};

export default Providers;
