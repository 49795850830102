import React, { useState, useEffect } from "react";
import profile from "../../../image/images.jpg";
import { AiFillStar, AiFillClockCircle } from "react-icons/ai";
import { HiChatAlt2 } from "react-icons/hi";
import { BsDot } from "react-icons/bs";
import { Modal } from "antd";
import {
  GETAVAILABLEPROVIDERS,
  GETPROVIDERSUMMARY,
} from "../../../graphql/User/job";
import { client } from "../../../apollo";
import { useParams } from "react-router-dom";
import { GiTrophyCup } from "react-icons/gi";
import { IoIosCloudDone } from "react-icons/io";

const ServiceProvider = ({ categoryid }) => {
  let { jobid } = useParams();
  const [model, setmodel] = useState(false);
  const [provider_list, setprovider_list] = useState([]);
  const [provider_details, setprovider_details] = useState([]);
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    sessionStorage.setItem("jobid",jobid)
    await client
      .query({
        query: GETAVAILABLEPROVIDERS,
        variables: {
          ondemandId: jobid,
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        setprovider_list(result.data.get_available_providers_of_the_job);
      });
  };
  const viewProvider = async (e) => {
    if (e.target.id.length !== 0) {
      setmodel(true);
      await client
        .query({
          query: GETPROVIDERSUMMARY,
          variables: {
            _id: e.target.id,
            category_id: categoryid,
          },
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          setprovider_details([result.data.get_provider_summary]);
        });
    }
  };
  const chatbtn = (e) => {
    window.location.replace(`/chat/${e.target.id}`);
  };
  return (
    <>
      <div className="mt-5">
        <h4>
          <b>Nearby Service Providers</b>
        </h4>
        <div className="review_list mt-3">
          {provider_list.length !== 0 ? (
            provider_list.map((data, index) => (
              <div
                className="review_item"
                key={index}
                id={data._id}
                onClick={viewProvider}
              >
                <div id={data._id}>
                  <img
                    id={data._id}
                    src={data.img_url !== null ? data.img_url : profile}
                    alt=""
                    className="profile_img"
                  />
                </div>
                <div id={data._id}>
                  <h6 id={data._id}>
                    <b id={data._id}>
                      {data.first_name} {data.last_name}
                    </b>
                  </h6>
                  <h6>
                    <AiFillStar className="star_icon" id={data._id} />{" "}
                    {data.rating}
                  </h6>
                </div>
                <div id={data._id}>
                  <button className="chatbtn" id={data._id} onClick={chatbtn}>
                    <HiChatAlt2 />
                    Chat
                  </button>
                </div>
                <div id={data._id}>
                  {data.online === "1" ? (
                    <label className="onlinelabel">
                      <BsDot className="online_icon" id={data._id} />
                      Online
                    </label>
                  ) : null}
                </div>
              </div>
            ))
          ) : (
            <div>
              <h6>No Providers Found..</h6>
            </div>
          )}
        </div>
      </div>
      <Modal
        title=""
        className="new_modal_provider"
        centered
        visible={model}
        destroyOnClose={true}
        onCancel={() => setmodel(false)}
      >
        {provider_details.length !== 0 ? (
          <div className="w-100 p-4">
            <h4>
              <b>Provider Details</b>
            </h4>
            <div className="review_item_provider">
              <div>
                <img
                  src={provider_details[0].img_url}
                  alt=""
                  className="provider_img"
                />
              </div>
              <div>
                <h6>
                  <b>
                    {provider_details[0].first_name}{" "}
                    {provider_details[0].last_name}
                  </b>
                </h6>
                <h6>
                  {provider_details[0].get_company_root_detail.company_name}
                </h6>
                <div>
                  <h6>
                    <AiFillStar className="star_icon" />{" "}
                    {provider_details[0].rating}
                  </h6>
                </div>
                {/* <button className="chatbtn">
                  <HiChatAlt2 />
                  Chat
                </button> */}
              </div>
            </div>
            {provider_details[0].description !== null ? (
              <div className="mt-3">
                <h5>
                  <b>Portfolio</b>
                </h5>
                <p>{provider_details[0].description}</p>
              </div>
            ) : null}
            <hr />
            {provider_details[0].overview !== null ? (
              <div className="mt-3">
                <p>
                  <GiTrophyCup /> Hired{" "}
                  {provider_details[0].overview.ondemandCategory} Times
                </p>
                <p>
                  <IoIosCloudDone /> {provider_details[0].overview.contract}{" "}
                  similar jobs done
                </p>
                <p>
                  <AiFillClockCircle /> {provider_details[0].overview.years}{" "}
                  Years in business
                </p>
              </div>
            ) : null}
            <hr />
            {provider_details[0].previous_work_done.length !== 0 ? (
              <div className="mt-3">
                <h5>
                  <b>Previous work done </b>
                </h5>
                <div className="doc_list mt-3">
                  {provider_details[0].previous_work_done.length !== 0
                    ? provider_details[0].previous_work_done.map(
                        (data, index) => (
                          <div className="dropzone" key={index}>
                            <img alt="" className="dropzone" src={data} />
                          </div>
                        )
                      )
                    : null}
                </div>
              </div>
            ) : null}

            {provider_details[0].professional_document_url.length !== 0 ? (
              <div className="mt-3">
                <h5>
                  <b>Professional Documents</b>
                </h5>
                <div className="doc_list mt-3">
                  {provider_details[0].professional_document_url.length !== 0
                    ? provider_details[0].professional_document_url.map(
                        (data, index) => (
                          <div className="dropzone" key={index}>
                            <img alt="" className="dropzone" src={data} />
                          </div>
                        )
                      )
                    : null}
                </div>
              </div>
            ) : null}

            <div className="mt-3">
              <h5>
                <b>Legal Documents</b>
              </h5>
              <div className="doc_list mt-3">
                {provider_details[0].get_pro_profile_doc !== 0 ? (
                  provider_details[0].get_pro_profile_doc.map((dataimg) =>
                    dataimg.model_type === "legal_document"
                      ? dataimg.get_sub_docs_images.length !== 0
                        ? dataimg.get_sub_docs_images.map((data, index) => (
                            <div className="dropzone" key={index}>
                              <img
                                alt=""
                                className="dropzone"
                                src={data.small_image}
                              />
                            </div>
                          ))
                        : null
                      : null
                  )
                ) : (
                  <div>
                    <p className="f_21">No file Found..</p>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3">
              <h5>
                <b>License Documents</b>
              </h5>
              <div className="doc_list mt-3">
                {provider_details[0].get_pro_profile_doc !== 0 ? (
                  provider_details[0].get_pro_profile_doc.map((dataimg) =>
                    dataimg.model_type === "license"
                      ? dataimg.get_sub_docs_images.length !== 0
                        ? dataimg.get_sub_docs_images.map((data, index) => (
                            <div className="dropzone" key={index}>
                              <img
                                alt=""
                                className="dropzone"
                                src={data.small_image}
                              />
                            </div>
                          ))
                        : null
                      : null
                  )
                ) : (
                  <div>
                    <p className="f_21">No file Found..</p>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-3">
              <h5>
                <b>Certificate Documents</b>
              </h5>
              <div className="doc_list mt-3">
                {provider_details[0].get_pro_profile_doc !== 0 ? (
                  provider_details[0].get_pro_profile_doc.map((dataimg) =>
                    dataimg.model_type === "certificate"
                      ? dataimg.get_sub_docs_images.length !== 0
                        ? dataimg.get_sub_docs_images.map((data, index) => (
                            <div className="dropzone" key={index}>
                              <img
                                alt=""
                                className="dropzone"
                                src={data.small_image}
                              />
                            </div>
                          ))
                        : null
                      : null
                  )
                ) : (
                  <div>
                    <p className="f_21">No file Found..</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default ServiceProvider;
