import React, { Suspense } from "react";
import { Skeleton, Row, Col } from 'antd';
import bg_img from '../../../../image/landing_page.webp'
// const BannerSearch = React.lazy(() => import('./BannerSearch'));
// const BannerIamges = React.lazy(() => import('./BannerImages'));
import useMobileDetect from 'use-mobile-detect-hook';
import BannerText from './BannerText'
const detectMobile = useMobileDetect();

const HomeBanner = () => {
    return (
        <Row className="px-md-5" >
            <Col className="mt-0 mt-md-5">
                {/* <img className="w-100 br_14 opacity_0_9" height={detectMobile.isMobile() ? 850 : 550} src={bg_img} /> */}
                <Row className="home_banner_bg w-100 d-flex align-items-center flex-column flex-md-row">
                    <Col sm={24} md={24} className="p-4">
                        <BannerText />
                    </Col>
                </Row>
            </Col>
        </Row >
    )
}
export default HomeBanner;
