import React, { Suspense } from "react";
import { withRouter } from "react-router";
import { client } from "../../../apollo";
import Skeleton from 'antd/es/skeleton';
import Spin from 'antd/es/spin'
import Layout from 'antd/es/layout'
import Form from 'antd/es/form'
import Card from 'antd/es/card'
import Row from 'antd/es/row'
import Col from 'antd/es/col';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import { Alert_msg } from '../../Comman/alert_msg';
import { GET_ONDEMANDS, GET_ALL_ONDEMAND_DATA } from '../../../graphql/Admin/ondemand';
import { MANAGE_CONTRACT_BOOKING } from '../../../graphql/Admin/contract'
import { Button, List, Tag, Table, Rate, Avatar, Modal} from 'antd';

const { Content } = Layout;
const Biding = React.lazy(() => import('./biding'));
const BidingList = React.lazy(() => import('./BidingList'));
const Milestone = React.lazy(() => import('./Milestone'));
const BillDetail = React.lazy(() => import('./BillDetail'));
const JobRating = React.lazy(() => import('../Review/ParticularRating/JobRating'));
const { Meta } = Card;


const columns = [
    {
        title: 'Invoice Number',
        dataIndex: 'invoice_no',
        key: 'invoice_no'
    },
    {
        title: 'Date',
        dataIndex: 'invoice_date_format',
        key: 'invoice_no'
    },
    {
        title: 'Amount',
        dataIndex: 'total',
        key: 'invoice_no'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => {
            let color;
            if (status === 'paid') {
                color = 'green';
            } else if(status === 'open'){
                color = 'orange';
            }else if (status === 'pending') {
                color = 'orange';
            } else if (status === 'overdue') {
                color = 'red';
            }
            return (
                <Tag color={color}>
                    {status}
                </Tag>
            );
        }
    }
];

  
class OnDemandDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            dataSource: [],
            update_data: {},
            update: 0,
            loading: false,
            loading_img: false,
            imageUrl: '',
            file: {},
            address: {},
            diffDuration: 0,
            previewVisible: false,
            previewImage: '',
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                simple: true,
            },
            basic_details: [],
            message: [],
            booking: [],
            booking_user: [],
            get_provider_user: [],
            booking_provider: [],
            booking_category: [],
            booking_status: 15,
            nav_text: ['', ''],
            u_rate: 0,
            p_rate: 0,
        };
    }

    componentDidMount() {
        if(this.props.match.params.jobid) this.props.match.params.id = this.props.match.params.jobid
        this.getAllData()
    }

    getAllData = async () => {
        this.setState({ loading: true, });
        let input_data = { _id: this.props.match.params.id}
        client.query({
            query: GET_ALL_ONDEMAND_DATA,
            variables: input_data,
            fetchPolicy: 'no-cache',
        }).then(result => {
             this.setState({
                loading: false,
                basic_details: result.data.get_ondemand_jobs_pagination.data[0],
                get_ondemand_jobs_category_question: result.data.get_ondemand_jobs_pagination.data[0].get_ondemand_jobs_category_question,
                get_provider_user: result.data.get_ondemand_jobs_pagination.data[0]?.approved_estimate?.get_provider_user,
                booking_user: result.data.get_ondemand_jobs_pagination.data[0].get_user,
                received_estimates: result.data.get_ondemand_jobs_pagination.data[0]?.received_estimates,
                shortlisted_estimates:result.data.get_ondemand_jobs_pagination.data[0]?.shortlisted_estimates,
                milestones:result.data.get_ondemand_jobs_pagination.data[0]?.approved_estimate?.get_milestones,
                invoices:result.data.get_ondemand_jobs_pagination.data[0]?.approved_estimate?.get_invoices,
                provider_review:result.data.get_ondemand_jobs_pagination.data[0]?.provider_review,                
                user_review:result.data.get_ondemand_jobs_pagination.data[0]?.user_review,
            })
        })
    };

    render() {
        return (
            <>
                <div
                    className="scrollable-container"
                    ref={node => {
                        this.container = node;
                    }}
                >
                    <Spin size="large" spinning={this.state.loading}>
                        <Row gutter={[12, 20]}>
                            <Col span={24}>
                                <Card bodyStyle={{ padding: "10px 10px" }}>
                                    <div className="d-flex align-items-center justify-content-between normal_font_size">
                                        <div>Project Detail </div>
                                        {/* <Tag color="#2db7f5" className='mx-3'>{BOOKING_STATUS_REVERSE[data?.booking_status]}</Tag> */}
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col lg={12} md={24}>
                                    <Suspense fallback={<Skeleton active />}>
                                        <Biding basic_details={this.state.basic_details} get_ondemand_jobs_category_question={this.state.get_ondemand_jobs_category_question}></Biding>
                                    </Suspense>
                                    <Suspense fallback={<Skeleton active />}>
                                        <Milestone milestones={this.state.milestones}></Milestone>
                                    </Suspense>
                            </Col>
                            <Col lg={12} md={24}>
                                    {this.state.get_provider_user?.length > 0 && <>
                                            <Card
                                                className="mt-3"
                                                hoverable
                                                title="Provider"
                                                // cover={<img
                                                //     className="h_200x"
                                                //     alt="gigzzy user img"
                                                //     src={this.state.get_provider_user?.[0]?.img_url}
                                                //     onError={({ currentTarget }) => {
                                                //         currentTarget.onerror = null;
                                                //         currentTarget.src = require('../../../image/no_img.png');
                                                //     }} />}
                                            >
                                                <div className="d-flex justify-content-between align-items-baseline pb-2">
                                                    <div>
                                                        Name
                                                    </div>
                                                    <div style={{fontWeight: 600}}>
                                                    {this.state.get_provider_user?.[0]?.first_name + this.state.get_provider_user?.[0]?.last_name}
                                                    </div>                                       
                                                    </div>
                                                <div className="d-flex justify-content-between align-items-center pb-2">
                                                        <div>Email</div>
                                                        <div>
                                                            <div>{this.state.get_provider_user?.[0]?.email} </div>
                                                            <div className="badge-info br_10 px-2 small">{this.state.get_provider_user?.[0]?.email}</div>
                                                        </div>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-baseline pb-2">
                                                        <div> Phone </div>
                                                        <div>{this.state.get_provider_user?.[0]?.phone_number}</div>
                                                </div>
                                                {this.state.provider_review?.length > 0 && <><div className="d-flex justify-content-between align-items-baseline pb-2">
                                                        <div> Rating </div>
                                                        <Rate disabled defaultValue={this.state.provider_review?.[0]?.rating} />   
                                                </div>
                                                <div className="d-flex justify-content-between align-items-baseline w-full flex-wrap">
                                                        <div className="w-50"> Discription </div>
                                                        <div className="w-50 d-flex justify-content-end">{this.state.provider_review?.[0]?.description || '-'}</div>
                                                </div> </>}
                                            </Card>
                                    </>
                                    }
                            
                                    <Card
                                        className="mt-3 w-full flex-wrap"
                                        hoverable
                                        title="User"
                                        // cover={<img
                                        //     alt="gigzzy user img"
                                        //     className="h_200x"
                                        //     src={this.state.booking_user?.[0]?.img_url}
                                        //     onError={({ currentTarget }) => {
                                        //         currentTarget.onerror = null;
                                        //         currentTarget.src = require('../../../image/no_img.png');
                                        //     }} />}
                                    >
                                    <div className="d-flex justify-content-between align-items-baseline pb-2">
                                            <div>
                                                Name
                                            </div>
                                            <div style={{fontWeight: 600}}>
                                            {this.state.booking_user?.[0]?.first_name + this.state.booking_user?.[0]?.last_name}
                                            </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center pb-2">
                                            <div>Email</div>
                                            <div>
                                                <div>{this.state.booking_user?.[0]?.email} </div>
                                                <div className="badge-info br_10 px-2 small">{this.state.booking_user?.[0]?.user_type}</div>
                                            </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-baseline pb-2">
                                            <div> Phone </div>
                                            <div>{this.state.booking_user?.[0]?.phone_number}</div>     
                                    </div>
                                    {this.state.user_review?.length > 0 && <><div className="d-flex justify-content-between align-items-baseline pb-2">
                                                    <div> Rating </div>
                                                    <Rate disabled defaultValue={this.state.user_review?.[0]?.rating} />           
                                        </div>
                                        <div className="d-flex justify-content-between align-items-baseline w-full flex-wrap">
                                                    <div className="w-50"> Description </div>
                                                    <div className="w-50 d-flex justify-content-end">{this.state.user_review?.[0]?.description}</div>
                                        </div></>}
                                    </Card>

                                    <Suspense fallback={<Skeleton active />}>
                                        <BidingList received_estimates={this.state.received_estimates} shortlisted_estimates={this.state.shortlisted_estimates}></BidingList>
                                    </Suspense>

                                    <Card
                                        className="mt-3 w-full"
                                        bodyStyle={{maxHeight: '600px', overflowY: 'auto', padding:"10px 8px"}}
                                        hoverable
                                        title="Invoices"
                                    >
                                        <Table
                                            dataSource={this.state.invoices}
                                            columns={columns}
                                            pagination={false}
                                        />
                                    </Card>
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </>
        );
    }
}
export default Form.create()(withRouter(OnDemandDetail));