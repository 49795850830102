import React, { useEffect, useRef, useState } from "react";
// import { AiOutlineClose,AiOutlineDownload, AiOutlineFileText } from "react-icons/ai";
import { AiOutlineClose,AiOutlineDownload, AiOutlineFileText, AiOutlineFilePdf, AiOutlineFileWord, AiOutlineFileExcel, AiOutlineFilePpt } from 'react-icons/ai';
import { MdAttachFile } from "react-icons/md";
import { GetChatInbox ,GetChatMessageQuery} from "../../../graphql/User/job";
import { useParams } from "react-router-dom";
import { client } from "../../../apollo";
import { SUBSCRIPTION_CHAT, ADD_MSG_CHAT, ADD_FILE_CHAT } from "../../../graphql/User/chat";
import useReactRouter from "use-react-router";
import { List, Avatar , Card, message} from 'antd';
import profile from "../../../image/no_img.png";
import { FieldInstance } from "twilio/lib/rest/autopilot/v1/assistant/task/field";
const Chat = () => {
  let { booking_id } = useParams();
  const [inbox_list, set_inbox_list] = useState();
  const [message_text, set_message_text] = useState(null);
  const [pagination_info, set_pagination_info] = useState({
    hasNextPage:false,
    nextPage:1
  });
  const [messages, set_messages] = useState([]);
  const [provider_data, set_provider_data] = useState(null);
  const [user_data, set_user_data] = useState(null);
  const [booking_data, set_booking_data] = useState(null);
  const [inbox_data, set_inbox_data] = useState(null);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const { Meta } = Card;
  
  useEffect(() => {
    get_inbox_chat();
    getsubscription();
  }, [booking_id]);

  const scrollToBottom = () => {
    var scroll = document.getElementById('scroll');
    scroll.scrollTop = scroll.scrollHeight;
    scroll.animate({ scrollTop: scroll.scrollHeight });
  }
  const get_inbox_chat = async () => {
    var providerdata = localStorage.getItem("user");
    set_user_data(JSON.parse(providerdata))
    var userid = JSON.parse(providerdata)._id;
    await client
        .query({
          query: GetChatInbox,
          variables: {
            "user_id":userid,
            "per_page":10,
            "page":pagination_info?.nextPage
          },
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          set_pagination_info(result.data.get_chat_inbox.pagination_info)
          set_inbox_list(result.data.get_chat_inbox.messages);
        });
  };

  const choose_provider = async (item) => {
    sessionStorage.setItem("inbox_data",JSON.stringify(item));
    window.location.replace("/inbox/"+item.booking_id);
  }
  const getsubscription = async () => {
    if(booking_id)
    {
      var providerdata = localStorage.getItem("user");
      var userdata = JSON.parse(providerdata);
      var inbox_data = sessionStorage.getItem("inbox_data");
      if(!inbox_data)
      {
        window.location.replace("/inbox");
      }
      else
      {
        inbox_data = JSON.parse(inbox_data);
        set_inbox_data(inbox_data);
        set_booking_data(inbox_data.OnDemands[0]);
        set_provider_data(inbox_data.provider[0])
      }
      
      
      await client
      .query({
        query: GetChatMessageQuery,
        variables: {
          booking_id: booking_id,
          provider_id:inbox_data.provider_id,
          user_id:userdata._id,
          role:userdata.role
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        set_messages(prevState => {
          return [...prevState,...result.data.get_message];
        });
        scrollToBottom();
      }).then((err) => {
        console.log(err);
      })

      await client.subscribe({
        query: SUBSCRIPTION_CHAT,
        variables: {
          provider_id: inbox_data.provider_id,
          user_id: userdata._id,
          bookingId: booking_id,
        },
        shouldResubscribe: false
      }).subscribe({
          next(result, loading, error) {
            if (result) {
              set_messages(prevState => {
                return [...prevState,...[result.data.messageSent]];
              });
              scrollToBottom();
            }
          }
      });
    }
  };
  const messageTextRef = useRef(null);

  const closebtn = () => {
    window.location.replace("/inbox");
  };
  const keyup_message_text = (e) => {
    if(e.key==='Enter')
    {
      send_message_submit();
    }
    else
    {
      set_message_text(e.target.value)
    }
  }
  const send_message_submit = () => {
    if(message_text.trim())
    {
      send_message()
    }
  }

  const send_message = async(files) => {
    var providerdata = localStorage.getItem("user");
    var userid = JSON.parse(providerdata)._id;
    await client
    .query({
      query: ADD_MSG_CHAT,
      variables: {
        "booking_id": booking_id,
        "user_id": userid,
        "provider_id": inbox_data.provider_id,
        "role":1,
        "message":message_text,
        "files": files ?? files
      },
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      messageTextRef.current.value=''
      set_message_text(null)
    });
  }


const handleFileupload = async (event) => {
  const files = event.target.files; // Get the selected files
  if (!files) return;
  try {
    await sendFiles(Array.from(files));
  } catch (error) {
    console.error('Error sending file:', error);
  }
};


const sendFiles = async (files) => {
  const providerData = localStorage.getItem("user");
  const userId = JSON.parse(providerData)._id;
  const result = await client.mutate({
    mutation: ADD_FILE_CHAT,
    variables: {
      "message_textdoc_type": "image",
      "user_id": userId,
      "job_id": booking_id,
      "file": files, 
    },
    fetchPolicy: "no-cache",
  });

  if(result){
  send_message(result.data.msg_uploading_files?.docs)
}
  scrollToBottom();
};


  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-3">
          <div className="card userlistdiv">
            {inbox_list && inbox_list.map((inbox_element,i)=>(
              <div key={i+'inbox'} className={(inbox_element.booking_id==booking_id)?'active_inbox inbox_body':'inbox_body'} onClick={()=>{choose_provider(inbox_element)}}>
                <div className="inbox_inner">
                  <object className="chatViewprofile" data={profile} type="image/jpeg">
                    <img
                      src={inbox_element?.provider[0]?.img_url}
                    />
                  </object>
                  <div>
                    <p className="m-0">{(inbox_element?.provider[0]?.name)?inbox_element?.provider[0]?.name:'-'}</p>
                    <p className="m-0 text-black-50">{inbox_element?.OnDemands[0]?.ondemand_ref} - {inbox_element?.OnDemands[0]?.get_category_names?.category_name}</p>
                    <span className="small">{inbox_element.msg_date+' '+inbox_element.msg_time}</span>
                  </div>
                </div>
              </div>
            ))}
            {(inbox_list)?<></>:<>No Records Found</>}
          </div>
        </div>
        <div className="col-md-9">
          {provider_data !== null ? (
            <div className="card">
              <div className="chathead">
                <div className="chatheader">
                  <object className="chatViewprofile" data={profile} type="image/jpeg">
                    <img
                      src={provider_data?.img_url}
                      alt={provider_data?.name}
                    />
                  </object>
                  <div>
                      {provider_data.name}
                      <p className="m-0 text-black-50">{booking_data?.ondemand_ref} - {booking_data?.get_category_names?.category_name}</p>
                  </div>
                </div>
                <AiOutlineClose className="closeicon" onClick={closebtn} />
              </div>
              <hr />
              <div className="messageplace" id="scroll">
                 {messages && messages.map((element, i) => (
                  <div key={i + 'container'}>
                    {/* Render message if it exists */}
                    {element.message != null && element.message.trim().length > 0 && (
                      <div key={i + 'chat'} className={(element.role === user_data.role) ? 'message_body right-message' : 'message_body left-message'}>
                        <div className="message_inner">
                          <p>{element.message}</p>
                          <span>{element.msg_date + ' ' + element.msg_time}</span>
                        </div>
                      </div>
                    )}

                  {/* Render files if they exist */}
                  {element.files.length > 0 && (
                    <div key={i + 'files'}>
                      {element.files.map((file, j) => (
                      <div key={j + 'file'} className={(element.role === user_data.role) ? 'message_body right-message' : 'message_body left-message'}>
                        <div className="message_inner">
                            <div className="d-flex align-items-center">
                            <a className="pr-2" href={`https://${process.env.REACT_APP_SECRET_NAME}/images/user/message/${file.large_image}`} download><AiOutlineDownload /></a>
                            <div className="filecard">
                              <span className="filetype text-uppercase">{file.doc_type}</span>
                              <span className="filename">{file.large_image}</span>
                            </div>
                            </div>
                        <span>{element.msg_date + ' ' + element.msg_time}</span>
                        </div>
                        </div>
                      ))}
                    </div>
                  )}
                  </div>
                 ))}
              </div>
              <hr />
              <div className="chathead">
                <div className="chatheader">
                  <div className="file-input">
                    <input
                      type="file"
                      name="file-input"
                      id="file-input"
                      className="file-input__input"
                      accept="image/png, image/jpeg,.doc,.docx,.pdf"
                      onChange={handleFileupload}
                    />
                    <label className="m-0" htmlFor="file-input">
                      <MdAttachFile className="addfileicon" />
                    </label>
                  </div>
                  <input
                    type="text"
                    ref={messageTextRef}
                    placeholder="Type a message"
                    className="messageinput"
                    onKeyUp={(e)=>keyup_message_text(e)}
                  />
                </div>
                <button disabled={!message_text} onClick={()=>send_message_submit()} className="sendbtn">Send</button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Chat;
