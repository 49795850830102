import React from "react";
import 'antd/dist/antd.css';
import useMobileDetect from 'use-mobile-detect-hook';
import { withRouter } from "react-router-dom";
import { Result, Button } from 'antd';
const detectMobile = useMobileDetect();
class NotFound extends React.Component {
    render() {
        return (<>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, Page Not Found."
                extra={<Button className={detectMobile.isMobile() ? "d-none" : ""} type="primary" onClick={() => { this.props.history.push("/"); }}>Back Home</Button>}
            />
        </>
        );
    }
}
export default withRouter(NotFound);