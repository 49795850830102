import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Icon, Button, Popconfirm, Upload } from "antd";
import { Alert_msg } from "../../Comman/alert_msg";
import { useMutation } from "@apollo/react-hooks";
import { client } from "../../../apollo";
import {
  CREATE_MILESTONE,
  UPDATE_MILESTONE,
} from "../../../graphql/User/milestone";
import size from "lodash/size";
import { useParams } from "react-router-dom";

const { TextArea } = Input;

const CreateMilestone = (props) => {
  let { jobid } = useParams();
  var form = props.form;
  const [count, set_count] = useState(2);
  const [form_count, set_form_count] = useState([]);

  useEffect(() => {
    let test_data = [];
    for (let i = 1; i <= count; i++) {
      test_data.push({
        count: i,
        file: [],
        image: [],
        title: "",
        description: "",
        error_title: false,
      });
    }
    set_form_count(test_data);
  }, [count]);

  const remove_from = (index) => {
    set_count(count - 1);
  };
  const save_milestone = (field, i) => {
    var biding_id = sessionStorage.getItem("biding_id");
    var provider_id = sessionStorage.getItem("provider_id");
    form.validateFields(async (err, values) => {
      if (!err) {
        let file = [];
        var milestone_data = [];

        if (size(values[`file_${i}`])) {
          file = values[`file_${i}`].map((inner_file) => {
            return inner_file.originFileObj;
          });
        }
        milestone_data.push({
          title: values[`title_${i}`],
          description: values[`description_${i}`],
          budget: values[`budget_${i}`],
          ondemand_id: jobid,
          biding_id: biding_id,
        });
        // milestone_data["title"] = values[`title_${i}`];
        // milestone_data["description"] = values[`description_${i}`];
        // milestone_data["budget"] = values[`budget_${i}`];
        // milestone_data["ondemand_id"] = jobid;
        // milestone_data["biding_id"] = biding_id;
        if (
          milestone_data[0]["budget"] &&
          milestone_data[0]["title"] &&
          milestone_data[0]["description"]
        ) {
          let input_data = {};
          if (size(file)) {
            input_data["file"] = file;
          }
          var providerdata = localStorage.getItem("user");
          var userid = JSON.parse(providerdata)._id;
          var locationcode = JSON.parse(providerdata).location_code;
          await client
            .query({
              query: CREATE_MILESTONE,
              variables: {
                user_id: userid,
                provider_id: provider_id,
                biding_id: biding_id,
                ondemand_id: jobid,
                // booking_status: 9,
                local_location_code: locationcode,
                file: file,
                milestone_data: milestone_data,
              },
              fetchPolicy: "no-cache",
            })
            .then((result) => {
              console.log(result);
            });
          // Alert_msg("Milestone saved..");
        } else {
          Alert_msg({ msg: "Please fill all the field", status: "failed" });
        }
      } else {
        Alert_msg({ msg: "Milestone update failed", status: "failed" });
      }
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const addbtn = () => {
    set_count(count + 1);
  };
  return (
    <>
      <Row className="container">
        <Form name="nested-milestone" className="">
          {(form_count || []).map((field, i) => {
            return (
              <>
                <Row gutter={[16, 0]}>
                  <Col span={24}>
                    <div
                      className="cursor_point d-flex text-success mt-2 mb-2"
                      onClick={addbtn}
                    >
                      <Icon className="py-1 mr-2" type="plus-circle" />{" "}
                      <div>Add Milestone</div>
                    </div>
                    <div className="d-flex w-100 justify-content-between normal_font_size pb-3">
                      <div> Milestone {i + 1}</div>
                      {form_count.length >= 2 && (
                        <Popconfirm
                          title="Sure to delete milestone ?"
                          onConfirm={() => remove_from()}
                        >
                          <div className="cursor_point d-flex justify-content-between align-items-center text-danger">
                            <Icon className="px-3" type="delete" />{" "}
                            <div>Remove</div>
                          </div>
                        </Popconfirm>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={24}>
                    <Form.Item label="Title" key={i}>
                      {form.getFieldDecorator(`title_${i}`, {
                        keys: "",
                        initialValue: "",
                        rules: [
                          { required: field["error_title"] ? true : false },
                        ],
                      })(<Input size={"large"} className="w-100" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={24}>
                    <Form.Item label="Description" key={i}>
                      {form.getFieldDecorator(`description_${i}`, {
                        keys: "",
                        initialValue: "",
                      })(<TextArea size={"large"} rows={6} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={6}>
                    <Form.Item label="Amount to be released" key={i}>
                      {form.getFieldDecorator(`budget_${i}`, {
                        keys: "",
                        initialValue: "",
                      })(
                        <Input
                          type="number"
                          size={"large"}
                          className="w-100"
                          min={1}
                          addonAfter="KSH"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={24}>
                    <div>
                      <Form.Item label="">
                        {form.getFieldDecorator(`file_${i}`, {
                          rules: [],
                          valuePropName: "fileList",
                          getValueFromEvent: normFile,
                        })(
                          <Upload
                            name="logo"
                            multiple={true}
                            listType="picture-card"
                            action="/upload.do"
                          >
                            <div>
                              <Icon type="plus" />
                              <div className="ant-upload-text">Upload</div>
                            </div>
                          </Upload>
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={6}>
                    {form_count.length >= 2 && (
                      <Popconfirm
                        title="Sure to delete milestone ?"
                        onConfirm={() => remove_from()}
                      >
                        <Button
                          size={"large"}
                          block
                          className="normal_font_size"
                        >
                          <div className="px-2"> Cancel</div>
                        </Button>
                      </Popconfirm>
                    )}
                  </Col>
                  <Col span={6}>
                    <Button
                      size={"large"}
                      block
                      onClick={() => {
                        save_milestone(field, i);
                      }}
                      className="bg-gradient-primary text-white normal_font_size"
                    >
                      <div className="px-2"> Done</div>
                    </Button>
                  </Col>
                </Row>
                <hr />
              </>
            );
          })}
        </Form>
      </Row>
    </>
  );
};
export default Form.create()(CreateMilestone);
