import React, { useState, useEffect } from "react";
import profile from "../../../image/images.jpg";
import { AiFillStar } from "react-icons/ai";
import { HiChatAlt2 } from "react-icons/hi";
import { BsDot } from "react-icons/bs";
import { Modal } from "antd";
import {
  GETAVAILABLEPROVIDERS,
  GETPROVIDERSUMMARY,
  BIDDING_DETAILS,
  BID_ACTION,
} from "../../../graphql/User/job";
import { client } from "../../../apollo";
import { useParams } from "react-router-dom";
import { Alert_msg } from "../../Comman/alert_msg";

const ReviewCategory = ({ categoryid }) => {
  let { jobid } = useParams();
  const [model, setmodel] = useState(false);
  const [viewall, setviewall] = useState(true);
  const [provider_list, setprovider_list] = useState([]);
  const [allprovider_list, setallprovider_list] = useState([]);
  const [provider_details, setprovider_details] = useState([]);
  const [biddingid, setbiddingid] = useState(null);
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    sessionStorage.setItem("jobid",jobid)
    var providerdata = localStorage.getItem("user");
    var userid = JSON.parse(providerdata)._id;
    var locationcode = JSON.parse(providerdata).location_code;
    await client
      .query({
        query: BIDDING_DETAILS,
        variables: {
          ondemandId: jobid,
          // user_id: "60db330b2d076b2e67afea96",
          user_id: userid,
          location_code: locationcode,
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        setallprovider_list(result.data.get_on_demand_biding_pagination.data);
        setprovider_list(
          result.data.get_on_demand_biding_pagination.data.slice(0, 4)
        );
      });
  };
  const viewallbtn = () => {
    setviewall(false);
    setprovider_list(allprovider_list);
  };
  const hideall = () => {
    setviewall(true);
    setprovider_list(allprovider_list.slice(0, 4));
  };
  const viewProvider = async (e) => {
    if (e.target.id.length !== 0) {
      setbiddingid(e.target.id);
      var filterdata = await provider_list.filter((data) => {
        return data._id == e.target.id;
      });
      setmodel(true);
      await client
        .query({
          query: GETPROVIDERSUMMARY,
          variables: {
            _id: filterdata[0].provider_id,
            category_id: categoryid,
          },
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          setprovider_details([result.data.get_provider_summary]);
        });
    }
  };
  const ViewProvider = async () => {
    window.location.replace(`/provider/${jobid}`);
  };
  const acceptbtn = async (e) => {
    var providerdata = localStorage.getItem("user");
    var userid = JSON.parse(providerdata)._id;
    var locationcode = JSON.parse(providerdata).location_code;
    await client
      .query({
        query: BID_ACTION,
        variables: {
          booking_status: 10,
          ondemand_id: jobid,
          biding_id: biddingid,
          provider_id: e.target.id,
          location_code: locationcode,
          role: 1,
          user_id: userid,
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        if (result.data.manage_ondemand_booking.status === "success") {
          Alert_msg({
            msg: "Project has been awarded",
            status: "success",
          });
        }
        setmodel(false);
        getalldata();
      });
  };
  const rejectbtn = async (e) => {
    var providerdata = localStorage.getItem("user");
    var userid = JSON.parse(providerdata)._id;
    var locationcode = JSON.parse(providerdata).location_code;
    await client
      .query({
        query: BID_ACTION,
        variables: {
          booking_status: 8,
          ondemand_id: jobid,
          biding_id: biddingid,
          provider_id: e.target.id,
          location_code: locationcode,
          role: 1,
          user_id: userid,
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        if (result.data.manage_ondemand_booking.status === "failed") {
          Alert_msg({
            msg: "Customer has Rejected your job bid",
            status: "success",
          });
        }
        setmodel(false);
        getalldata();
      });
  };
  const chatbtn = (e) => {
    window.location.replace(`/chat/${e.target.id}`);
  };
  return (
    <>
      <div className="mt-5">
        <h4>
          <b>Job Request</b>
        </h4>
        <div className="review_list mt-3">
          {provider_list.length !== 0 ? (
            provider_list.map((data, index) => (
              <div
                className="review_item"
                key={index}
                id={data._id}
                onClick={viewProvider}
              >
                <div id={data._id}>
                  <img
                    id={data._id}
                    src={
                      data.get_user[0].img_url !== null
                        ? data.get_user[0].img_url
                        : profile
                    }
                    alt=""
                    className="profile_img"
                  />
                </div>
                <div id={data._id}>
                  <h6 id={data._id}>
                    <b id={data._id}>
                      {data.get_user[0].first_name} {data.get_user[0].last_name}
                    </b>
                  </h6>
                  <h6>
                    <AiFillStar className="star_icon" id={data._id} />{" "}
                    {data.get_user[0].rating}
                  </h6>
                  {/* <p className="mt-1">
                    Duration{" "}
                    <span>
                      <b>{data.timeline}</b>
                    </span>
                  </p> */}
                </div>
                <div id={data._id}>
                  <button className="chatbtn" id={data.provider_id} onClick={chatbtn}>
                    <HiChatAlt2 />
                    Chat
                  </button>
                </div>
                <div id={data._id}>
                  {data.get_user[0].online === "1" ? (
                    <label className="onlinelabel">
                      <BsDot className="online_icon" id={data._id} /> Online
                    </label>
                  ) : null}
                </div>
                <div className="itemlist">
                  <div className="item_list">
                    <h6>Response</h6>
                    <h6>
                      <b>
                        {data.response_time !== null ? data.response_time : 0}{" "}
                        {data.response_time_formate}
                      </b>
                    </h6>
                  </div>
                  <div className="item_list">
                    <h6>Distance</h6>
                    <h6>
                      <b>{data.find_kilometer.kilometre} Km</b>
                    </h6>
                  </div>
                </div>
                <div>
                  <h6>
                    <b>{data.budget}</b>
                  </h6>
                </div>
              </div>
            ))
          ) : (
            <div>
              <h6>No Providers Found..</h6>
            </div>
          )}
        </div>
        {allprovider_list.length > 4 ? (
          viewall === true ? (
            <center>
              <button className="mt-3 viewallBtn" onClick={viewallbtn}>
                View all request
              </button>
            </center>
          ) : (
            <center>
              <button className="mt-3 viewallBtn" onClick={hideall}>
                Hide all
              </button>
            </center>
          )
        ) : null}

        <center>
          <button className="mt-3 accbtn" onClick={ViewProvider}>
            View Nearby Providers
          </button>
        </center>
      </div>
      <Modal
        title=""
        className="new_modal_provider"
        centered
        visible={model}
        destroyOnClose={true}
        onCancel={() => setmodel(false)}
      >
        {provider_details.length !== 0 ? (
          <div className="w-100 p-4">
            <h4>
              <b>Provider Details</b>
            </h4>
            <div className="review_item_provider">
              <div>
                <img
                  src={provider_details[0].img_url}
                  alt=""
                  className="provider_img"
                />
              </div>
              <div>
                <h6>
                  <b>
                    {provider_details[0].first_name}{" "}
                    {provider_details[0].last_name}
                  </b>
                </h6>
                <h6>
                  {provider_details[0].get_company_root_detail.company_name}
                </h6>
                <p>{provider_details[0].description}</p>
                <button className="chatbtn" id={provider_details[0]._id} onClick={chatbtn}>
                  <HiChatAlt2 />
                  Chat
                </button>
              </div>
              <div>
                <h6>
                  <AiFillStar className="star_icon" />{" "}
                  {provider_details[0].rating}
                </h6>
              </div>
            </div>
            {/* <div className="response_div">
              <h5>
                Response in about <b>50 mins</b>
              </h5>
              <h2 className="code">Ksh 20</h2>
              <h5>Estimate Project Price</h5>
            </div> */}
            <div className="response_div_new mt-3">
              <h5>
                <b>Experience : {provider_details[0].overview.years} Years</b>
              </h5>
            </div>
            {provider_details[0].previous_work_done.length !== 0 ? (
              <div className="mt-3">
                <h5>
                  <b>Previous work done </b>
                </h5>
                <div className="doc_list mt-3">
                  {provider_details[0].previous_work_done.length !== 0
                    ? provider_details[0].previous_work_done.map(
                        (data, index) => (
                          <div className="dropzone" key={index}>
                            <img alt="" className="dropzone" src={data} />
                          </div>
                        )
                      )
                    : null}
                </div>
              </div>
            ) : null}
            {provider_details[0].personal_document_url.length !== 0 ? (
              <div className="mt-3">
                <h5>
                  <b>Personal Documents</b>
                </h5>
                <div className="doc_list mt-3">
                  {provider_details[0].personal_document_url.length !== 0
                    ? provider_details[0].personal_document_url.map(
                        (data, index) => (
                          <div className="dropzone" key={index}>
                            <img alt="" className="dropzone" src={data} />
                          </div>
                        )
                      )
                    : null}
                </div>
              </div>
            ) : null}

            {provider_details[0].professional_document_url.length !== 0 ? (
              <div className="mt-3">
                <h5>
                  <b>Professional Documents</b>
                </h5>
                <div className="doc_list mt-3">
                  {provider_details[0].professional_document_url.length !== 0
                    ? provider_details[0].professional_document_url.map(
                        (data, index) => (
                          <div className="dropzone" key={index}>
                            <img alt="" className="dropzone" src={data} />
                          </div>
                        )
                      )
                    : null}
                </div>
              </div>
            ) : null}
            <div className="mt-3">
              <h5>
                <b>Legal Documents</b>
              </h5>
              <div className="doc_list mt-3">
                {provider_details[0].get_pro_profile_doc !== 0 ? (
                  provider_details[0].get_pro_profile_doc.map((dataimg) =>
                    dataimg.model_type === "legal_document"
                      ? dataimg.get_sub_docs_images.length !== 0
                        ? dataimg.get_sub_docs_images.map((data, index) => (
                            <div className="dropzone" key={index}>
                              <img
                                alt=""
                                className="dropzone"
                                src={data.small_image}
                              />
                            </div>
                          ))
                        : null
                      : null
                  )
                ) : (
                  <div>
                    <p className="f_21">No file Found..</p>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3">
              <h5>
                <b>License Documents</b>
              </h5>
              <div className="doc_list mt-3">
                {provider_details[0].get_pro_profile_doc !== 0 ? (
                  provider_details[0].get_pro_profile_doc.map((dataimg) =>
                    dataimg.model_type === "license"
                      ? dataimg.get_sub_docs_images.length !== 0
                        ? dataimg.get_sub_docs_images.map((data, index) => (
                            <div className="dropzone" key={index}>
                              <img
                                alt=""
                                className="dropzone"
                                src={data.small_image}
                              />
                            </div>
                          ))
                        : null
                      : null
                  )
                ) : (
                  <div>
                    <p className="f_21">No file Found..</p>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-3">
              <h5>
                <b>Certificate Documents</b>
              </h5>
              <div className="doc_list mt-3">
                {provider_details[0].get_pro_profile_doc !== 0 ? (
                  provider_details[0].get_pro_profile_doc.map((dataimg) =>
                    dataimg.model_type === "certificate"
                      ? dataimg.get_sub_docs_images.length !== 0
                        ? dataimg.get_sub_docs_images.map((data, index) => (
                            <div className="dropzone" key={index}>
                              <img
                                alt=""
                                className="dropzone"
                                src={data.small_image}
                              />
                            </div>
                          ))
                        : null
                      : null
                  )
                ) : (
                  <div>
                    <p className="f_21">No file Found..</p>
                  </div>
                )}
              </div>
            </div>
            <div className="button_group mt-5">
              <button
                className="rejbtn"
                id={provider_details[0]._id}
                onClick={rejectbtn}
              >
                Reject
              </button>
              <button
                className="accbtn"
                id={provider_details[0]._id}
                onClick={acceptbtn}
              >
                Accept
              </button>
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default ReviewCategory;
