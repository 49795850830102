export const menu_data = [
    {
        key: "/admin-dashboard",
        icon: "dashboard",
        title: "Dashboard",
        link: "/admin-dashboard",
    },
    {
        key: "/admin-user",
        icon: "user",
        title: "Users",
        link: "/admin-user",
        permission: "view_user"
    }, {
        key: "/admin-provider",
        icon: "shop",
        title: "Providers",
        link: "/admin-provider",
        permission: "view_provider"
    }, {
        key: "/admin-company",
        icon: "bank",
        title: "Companys",
        link: "/admin-company",
        permission: "view_company"
    }, {
        key: "/admin-organization_industry",
        icon: "file-search",
        title: "Companys Types",
        link: "/admin-organization_industry",
        permission: "view_organization_industry"
    }, {
        key: "/admin-maincategory",
        link: "/admin-maincategory",
        permission: "view_category",
        icon: "deployment-unit",
        title: "Manage Category"
    },{
        key: "/admin-category_tag",
        link: "/admin-category_tag",
        permission: "view_category",
        icon: "api",
        title: "Pro Category Tag"
    },  {
        key: "/admin-projects",
        link: "/admin-projects",     // admin-ondemand
        permission: "view_booking",
        icon: "appstore",
        title: "Projects"
    }, 
    // {
    //     key: "/admin-contract",
    //     link: "/admin-contract",
    //     permission: "view_contract",
    //     icon: "hourglass",
    //     title: "Project"
    // }, 
    {
        key: "/admin-review",
        icon: "star",
        title: "Reviews",
        link: "/admin-review",
        permission: "view_review"
    }, 
    // {
    //     key: "/admin-payouts",
    //     icon: "transaction",
    //     title: "Payouts",
    //     link: "/admin-payouts",
    //     permission: "view_payout"
    // }, 
    {
        key: "/admin-certificate",
        icon: "file-unknown",
        title: "Certificates",
        link: "/admin-certificate",
        permission: "view_certificate"
    }, {
        key: "/admin-subscriptions",
        link: "/admin-subscriptions",
        permission: "view_subscriptions_plan",
        icon: "crown",
        title: "plan's"
    }, {
        key: "/admin-subscriber",
        link: "/admin-subscriber",
        permission: "view_subscriber",
        icon: "team",
        title: "subscriber"
    }, {
        key: "/admin-static",
        icon: "book",
        title: "Static Pages",
        link: "/admin-static",
        permission: "view_staticContent"
    }, {
        key: "/admin-currency",
        icon: "dollar",
        title: "Currency",
        link: "/admin-currency",
        permission: "view_currency"
    }, {
        key: "/admin-home-theme",
        icon: "bg-colors",
        title: "Home Page Theme",
        link: "/admin-home-theme",
        permission: ""
    },
    //  {
    //     key: "/admin-home-manage",
    //     icon: "highlight",
    //     title: "Home Manage",
    //     link: "/admin-home-manage",
    //     permission: ""
    // }, 
    {
        key: "/admin-roles",
        icon: "usergroup-add",
        title: "Manage Admin",
        link: "/admin-roles",
        permission: "view_admin"
    },
    //Report Menu Data
    {
        key: "/admin-report/admin-user-report",
        icon: "line-chart",
        title: "Reports",
        link: "/admin-report/admin-user-report",
        permission: ""
    },
]
