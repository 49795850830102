import React from "react";
import { Menu, Icon, Layout } from "antd";
import { BrowserRouter as Router, Link, withRouter } from "react-router-dom";
import main from "../../../image/Gigzzy.webp";
import "../../../scss/template.scss";
import RoleView, { RoleViewFunction } from '../../Comman/roles_permission_view'
import split from "lodash/split";
import { menu_data } from './menu_data'
const { Sider } = Layout;
const { SubMenu } = Menu;

class AdminSider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      change: 1,
      openKeys: [],
      mainKey: []
    };
  }

  componentDidMount() {
    this.setState({
      mainKey: [`/${split(this.props.location.pathname, '/')[1]}`]
    })
    let jobsarray = ['jobs', 'contract', 'booking', 'request']
    let categorysarray = ['category', 'subcategory',]
    let subscriptionsarray = ['subscriptions','subscriber']

    const jobsarrayresult = jobsarray.some(term => this.props.location.pathname.includes(term))
    const categorysarrayresult = categorysarray.some(term => this.props.location.pathname.includes(term))
    const subscriptionsarrayresult = subscriptionsarray.some(term => this.props.location.pathname.includes(term))


    if (jobsarrayresult) {
      this.setState({
        openKeys: ['jobs']
      })
    } else if (categorysarrayresult) {
      this.setState({
        openKeys: ['category']
      })
    } else if (subscriptionsarrayresult) {
      this.setState({
        openKeys: ['subscriptions']
      })
    }
  }

  onSelect = (e) => {
    this.setState({
      openKeys: e
    })
  }
  render() {
    console.log('From AdminSider.js this.props, this.state, ',this.props,this.state);
    return (
      <div>
        <Sider
          style={{ overflow: "auto", height: "100vh", left: 0 }}
          className="admin_side_bar_scroll d-none d-sm-none d-lg-block d-xl-block d-xxl-block template_sider bg-gradient-primary"
          trigger={null}
          collapsible
          collapsed={this.props.update_collapsed}
        >
          <div className="logo d-flex justify-content-around">
            <img
              src={main}
              alt={"gigzzy"}
              onClick={() => {
                this.props.history.push("/admin-dashboard");
              }}
              className="object_fit cursor_point"
            />
          </div>
          <Menu
            theme="dark"
            selectedKeys={this.state.mainKey}
            mode="inline"
            openKeys={this.state.openKeys}
            onOpenChange={this.onSelect}
          >
             {menu_data.map(menu_item => {
              if (menu_item.menu_type) {
                return (
                  <SubMenu key={menu_item.menu_type_key} title={
                    <div className="d-flex align-items-center">
                      <Icon type={menu_item.menu_type_icon} /><div>{menu_item.menu_type_title} </div></div>} >
                    {menu_item.sub_menu_data.map(sub_menu_item => {
                      let permission = RoleViewFunction(sub_menu_item.permission)
                      if (permission) {
                        return (
                          <Menu.Item key={sub_menu_item.key}>
                            <Icon type={sub_menu_item.icon} />
                            <span>{sub_menu_item.title}</span>
                            <Link to={sub_menu_item.link} />
                          </Menu.Item>
                        )
                      }
                    })}
                  </SubMenu>
                )
              } else {
                let permission = RoleViewFunction(menu_item.permission)
                if (permission) {
                  return (
                    <Menu.Item key={menu_item.key}>
                      <Icon type={menu_item.icon} />
                      <span>{menu_item.title}</span>
                      <Link to={menu_item.link} />
                    </Menu.Item>
                  )
                }
              }
            })}
           
          </Menu>
        </Sider>
      </div>
    );
  }
}
export default withRouter(AdminSider);
