import gql from "graphql-tag";
export const ADD_MSG_CHAT = gql`
mutation sendMessage(
  $booking_id: ID
  $user_id: ID
  $provider_id: ID
  $message: String
  $role: Int
  $files: JSON
) {
  add_message(
    booking_id: $booking_id
    user_id: $user_id
    provider_id: $provider_id
    message: $message
    role: $role
    files: $files
  ) {
    message
    msg_date
    msg_time
    files {
      small_image
      large_image
      doc_type
      image_tag
      detail_img_id
      user_id
      model_type
    }
    user(_id: $user_id) {
      name
      img_url
    }
    provider(_id: $provider_id) {
      name
      img_url
    }
  }
}`
export const SUBSCRIPTION_CHAT = gql`
  subscription ReciveMessage($booking_id: ID, $provider_id: ID, $user_id: ID) {
    messageSent(
      user_id:$user_id,
      provider_id:$provider_id,
      booking_id:$booking_id,
      ) {
      message
      createdAt
      role
      user{
          name
          img_url
      }
      msg_date_timestamp
      provider{
          name
          img_url
      }
      files{
          small_image
          large_image
          detail_img_id
          model_type
          doc_type
          user_id
          image_tag
      }
      file_type
      msg_date
      msg_time
    }
  }
`;

export const ADD_FILE_CHAT = gql`
mutation MessageUploadFiles($doc_type: String, $user_id: String, $job_id: String, $file: [Upload]) {
  msg_uploading_files(
  doc_type: $doc_type
  user_id: $user_id
  job_id: $job_id
  file: $file
  ) {
  __typename
  docs {
      __typename
      small_image
      large_image
      doc_type
      image_tag
      detail_img_id
      user_id
      model_type
  }
  message
  status
  }
}`