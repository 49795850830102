import React, { useState, useEffect } from "react";
import "./style.css";
import { BiChevronRight, BiUpload } from "react-icons/bi";
import {
  ANSWER_QUESTION,
  ANSWER_QUESTION_checkbox,
  ANSWER_QUESTION_TEXTBOX,
  ANSWER_QUESTION_FILE,
  GET_QUESTION,
  DELETE_FILE_IMAGE,
} from "../../../graphql/User/job";
import { client } from "../../../apollo";
import { useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

const Question = ({
  Question_data,
  Category_data,
  question_id,
  getquestionid,
  Nextquestion,
  Oldquestion,
}) => {
  let { categoryid } = useParams();
  const [textvalue, settextvalue] = useState(null);
  const [selectedanswer, setselectedanswer] = useState(null);
  const [upload_file, setupload_file] = useState(false);
  const [upload_file_data, setupload_file_data] = useState([]);
  const [isOtherClicked, setIsOtherClicked] = useState(true);
  const [otherTextAreaInput, setOtherTextAreaInput] = useState(null);

  var checkboxs = document.querySelectorAll(".checkbox_input");
  for (var i = 0; i < checkboxs.length; i++) {
    checkboxs[i].checked = false;
  }
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var providerdata = localStorage.getItem("user");
    if (JSON.parse(providerdata)._id !== null) {
      await client
        .query({
          query: GET_QUESTION,
          variables: {
            _id: categoryid,
            user_id: JSON.parse(providerdata)._id,
          },
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          var finalfile = [];
          if (
            result.data.get_ondemand_jobs[0].get_ondemand_jobs_category_question
              .length !== 0
          ) {
            for (
              var i = 0;
              i <
              result.data.get_ondemand_jobs[0]
                .get_ondemand_jobs_category_question.length;
              i++
            ) {
              if (
                result.data.get_ondemand_jobs[0]
                  .get_ondemand_jobs_category_question[i].option_type === "file"
              ) {
                finalfile.push(
                  result.data.get_ondemand_jobs[0]
                    .get_ondemand_jobs_category_question[i].get_ondemand_files
                );
              }
            }
          }
          setupload_file(true);
          setupload_file_data(finalfile[0]);
        });
    }
  };
  setTimeout(() => {
    if (Question_data.option_type == "checkbox") {
      if (Question_data.multiple_option.length !== 0) {
        for (var i = 0; i < Question_data.multiple_option.length; i++) {
          if (Question_data.multiple_option[i].is_select == true) {
            if (document.getElementById(`check${i}`) !== null) {
              document.getElementById(`check${i}`).checked = true;
            }
          }
        }
      }
    }
  }, 1000);
  const savebtn = async () => {
    if (Question_data.option_type === "radio") {
      await client
        .query({
          query: ANSWER_QUESTION,
          variables: {
            _id: categoryid,
            qid: Question_data._id,
            type: "radio",
            multiple_option: Question_data.multiple_option,
          },
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          if (result.data.Update_OnDemand_Answer.status === "success") {
            Nextquestion(question_id);
            setselectedanswer(null);
          }
        });
    } else if (Question_data.option_type === "checkbox") {
      await client
        .query({
          query: ANSWER_QUESTION_checkbox,
          variables: {
            _id: categoryid,
            qid: Question_data._id,
            type: "checkbox",
            multiple_option: Question_data.multiple_option,
          },
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          if (result.data.Update_OnDemand_Answer.status === "success") {
            Nextquestion(question_id);
          }
        });
    } else if (Question_data.option_type === "textbox") {
      await client
        .query({
          query: ANSWER_QUESTION_TEXTBOX,
          variables: {
            _id: categoryid,
            qid: Question_data._id,
            type: "textbox",
            answer: textvalue,
          },
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          if (result.data.Update_OnDemand_Answer.status === "success") {
            Nextquestion(question_id);
          }
        });
    } else {
      Nextquestion(question_id);
    }
  };
  const changeoption = (e) => {
    if (e.target.id) {
      for (var i = 0; i < Question_data.multiple_option.length; i++) {
        if (i == e.target.id) {
          setselectedanswer(e.target.id);
          if (Question_data.multiple_option[i]["is_select"] == true) {
            Question_data.multiple_option[i]["is_select"] = null;
          } else {
            Question_data.multiple_option[i]["is_select"] = true;
          }
        } else {
          Question_data.multiple_option[i].is_select = null;
        }
      }
    }
  };
  const changecheckbox = (e) => {
    var id = e.target.id.split("check")[1];
    if (id) {
      for (var i = 0; i < Question_data.multiple_option.length; i++) {
        if (i == id) {
          if (Question_data.multiple_option[i]["is_select"] == true) {
            Question_data.multiple_option[i]["is_select"] = null;
          } else {
            Question_data.multiple_option[i]["is_select"] = true;
          }
        }
      }
      if (document.getElementById(e.target.id).checked == true) {
        document.getElementById(e.target.id).checked = false;
      } else {
        document.getElementById(e.target.id).checked = true;
      }
    }
  };
  const changecheckbox_new = (e, data) => {
    if (data.label === "Other" && e.target.id) {
      setIsOtherClicked(!isOtherClicked);
    }
    if (e.target.id) {
      for (var i = 0; i < Question_data.multiple_option.length; i++) {
        if (i == e.target.id) {
          if (Question_data.multiple_option[i]["is_select"] == true) {
            Question_data.multiple_option[i]["is_select"] = null;
          } else {
            Question_data.multiple_option[i]["is_select"] = true;
          }
        }
      }
      if (document.getElementById(`check${e.target.id}`).checked == true) {
        document.getElementById(`check${e.target.id}`).checked = false;
      } else {
        document.getElementById(`check${e.target.id}`).checked = true;
      }
    }
  };
  const getfile = async (e) => {
    const files = Array.from(e.target.files);
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("file[]", files[i]);
    }
    await client
      .query({
        query: ANSWER_QUESTION_FILE,
        variables: {
          file: formData.getAll("file[]"),
          ondemand_id: categoryid,
          q_id: Question_data._id,
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        if (result.data.OnDemandJobFileUpload.status === "success") {
          setupload_file(true);
          setupload_file_data(
            result.data.OnDemandJobFileUpload.get_ondemand_files
          );
        }
      });
  };
  const remove_btn = async (e) => {
    await client
      .query({
        query: DELETE_FILE_IMAGE,
        variables: {
          _id: e.target.id,
          ondemand_id: categoryid,
        },
        fetchPolicy: "no-cache",
      })
      .then(async (result) => {
        if (result.data.delete_ondemand_image.msg === "deleted success") {
          var providerdata = localStorage.getItem("user");
          if (JSON.parse(providerdata)._id !== null) {
            await client
              .query({
                query: GET_QUESTION,
                variables: {
                  _id: categoryid,
                  user_id: JSON.parse(providerdata)._id,
                },
                fetchPolicy: "no-cache",
              })
              .then((result) => {
                var finalfile = [];
                if (
                  result.data.get_ondemand_jobs[0]
                    .get_ondemand_jobs_category_question.length !== 0
                ) {
                  for (
                    var i = 0;
                    i <
                    result.data.get_ondemand_jobs[0]
                      .get_ondemand_jobs_category_question.length;
                    i++
                  ) {
                    if (
                      result.data.get_ondemand_jobs[0]
                        .get_ondemand_jobs_category_question[i].option_type ===
                      "file"
                    ) {
                      finalfile.push(
                        result.data.get_ondemand_jobs[0]
                          .get_ondemand_jobs_category_question[i]
                          .get_ondemand_files
                      );
                    }
                  }
                }
                setupload_file(true);
                setupload_file_data(finalfile[0]);
              });
          }
        }
      });
  };

  const handleOtherTextInput = (e, data) => {
    setOtherTextAreaInput(e.target.value);
    data.value = e.target.value;
  };

  return (
    <>
      <div className="backbtndiv">
        <h6
          id={question_id}
          onClick={() => {
            Oldquestion();
          }}
        >
          <IoMdArrowRoundBack className="backbtn" />
          <b className="backname">Back</b>
        </h6>
        {Question_data.option_type !== "file" ? (
          <div className="sidebardivnewquestion">
            <div className="select_catdiv">
              <h2 className="quicksand_font_bold f_30 text-center">
                {Category_data.category_name}
              </h2>
              <div className="address_listnew">
                <h2 className="newheadingclassbig">Project Details</h2>
                <h2 className="newheadingclass mt-4">
                  {Question_data.question}
                </h2>
              </div>
              {Question_data.option_type === "radio" ? (
                <>
                  {Question_data.multiple_option.length &&
                    Question_data.multiple_option.map((data, index) => {
                      return (
                        <>
                          <div
                            className="address_listnew"
                            key={index}
                            id={index}
                            onClick={(e) => changeoption(e)}
                          >
                            <div
                              id={index}
                              className="d-flex align-items-start"
                            >
                              {data.is_select === true ||
                              selectedanswer == index ? (
                                <div className="mt-2">
                                  <input
                                    type="radio"
                                    id={index}
                                    className="checkbox_input"
                                    name="fav_language"
                                    value={index}
                                    checked
                                  />
                                </div>
                              ) : (
                                <div className="mt-2">
                                  <input
                                    type="radio"
                                    id={index}
                                    className="checkbox_input"
                                    name="fav_language"
                                    value={index}
                                  />
                                </div>
                              )}

                              <label
                                className="cat_label"
                                // onClick={(e) => changeoption(e)}
                                id={index}
                                htmlFor={index}
                              >
                                {data.label}
                                {data.label === "Other" && data.is_select && (
                                  <div className="pt-2">
                                    <textarea
                                      rows="4"
                                      value={data.value}
                                      onChange={(e) =>
                                        handleOtherTextInput(e, data)
                                      }
                                      placeholder="Enter your input here"
                                    />
                                  </div>
                                )}
                              </label>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </>
              ) : Question_data.option_type === "checkbox" ? (
                <>
                  {Question_data.multiple_option.length !== 0
                    ? Question_data.multiple_option.map((data, index) => (
                        <div
                          className="address_listnew"
                          key={index}
                          onClick={changecheckbox}
                        >
                          <div className="d-flex align-items-start">
                            <div className="mt-2">
                              <input
                                type="checkbox"
                                id={`check${index}`}
                                name="fav_language"
                                className="checkbox_input"
                                value={index}
                              />
                            </div>
                            <label
                              className="cat_label"
                              htmlFor={index}
                              id={index}
                              onClick={(e) => changecheckbox_new(e, data)}
                            >
                              {data.label}
                              {data.label === "Other" && data.is_select && (
                                <div className="pt-2">
                                  <textarea
                                    rows="4"
                                    value={data.value}
                                    onChange={(e) =>
                                      handleOtherTextInput(e, data)
                                    }
                                    placeholder="Enter your input here"
                                  />
                                </div>
                              )}
                            </label>
                          </div>
                        </div>
                      ))
                    : null}
                </>
              ) : Question_data.option_type === "textbox" ? (
                <div className="address_listnew">
                  <textarea
                    className="form-control textarea_ques"
                    rows="5"
                    onChange={(e) => {
                      settextvalue(e.target.value);
                    }}
                    defaultValue={Question_data.answer}
                  ></textarea>
                </div>
              ) : null}

              <div className="btn_grp">
                {Question_data.is_skip == true ? (
                  <h6
                    className="bold quicksand_font_bold f_20 skipbtn"
                    id={question_id}
                    onClick={getquestionid}
                  >
                    Skip <BiChevronRight />
                  </h6>
                ) : null}

                <button className="confirm_btn" onClick={savebtn}>
                  NEXT
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="sidebardivnewquestionnew">
            <div className="select_catdiv">
              <h4 className="quicksand_font_bold f_30 text-center">
                {Category_data.category_name}
              </h4>
              <div className="address_listnew">
                <h2 className="newheadingclassbig">Project Details</h2>
                <h2 className="newheadingclass mt-4">
                  {Question_data.question}
                </h2>
              </div>

              {Question_data.option_type === "file" ? (
                <div className="address_listnew">
                  <div className="file-input">
                    <input
                      type="file"
                      name="file-input"
                      id="file-input"
                      className="file-input__input"
                      accept="image/png, image/jpeg,.doc,.docx,.pdf"
                      multiple
                      onChange={getfile}
                    />
                    <label className="file-input__label" htmlFor="file-input">
                      <BiUpload />
                      <span>Upload File</span>
                    </label>
                  </div>
                  <div className="doc_list mt-3">
                    {upload_file == true
                      ? upload_file_data.length !== 0
                        ? upload_file_data.map((dataimg, index1) => (
                            <div className="image-area" key={index1}>
                              <img src={dataimg.small_image} alt="Preview" />
                              <a
                                className="remove-image"
                                id={dataimg._id}
                                onClick={remove_btn}
                                href="##"
                                style={{ display: "inline" }}
                              >
                                &#215;
                              </a>
                            </div>
                          ))
                        : null
                      : Question_data.get_ondemand_files.length !== 0
                      ? Question_data.get_ondemand_files.map(
                          (dataimg, index1) => (
                            <div className="image-area" key={index1}>
                              <img src={dataimg.small_image} alt="Preview" />
                              <a
                                className="remove-image"
                                id={dataimg._id}
                                onClick={remove_btn}
                                href="##"
                                style={{ display: "inline" }}
                              >
                                &#215;
                              </a>
                            </div>
                          )
                        )
                      : null}
                  </div>
                </div>
              ) : null}
              <div className="btn_grp">
                {Question_data.is_skip == true ? (
                  <h6
                    className="bold quicksand_font_bold f_20 skipbtn"
                    id={question_id}
                    onClick={getquestionid}
                  >
                    Skip <BiChevronRight />
                  </h6>
                ) : null}

                <button className="confirm_btn" onClick={savebtn}>
                  NEXT
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Question;
