import React, { useState, useEffect } from "react";
import Location from "./Location";
import { useParams } from "react-router-dom";
import { GET_QUESTION } from "../../../graphql/User/job";
import { client } from "../../../apollo";
import Question from "./Question";
import ProjectReview from "./ProjectReview";

const Job = () => {
  const [selectedpage, setselectedpage] = useState("Location");
  const [question_data, setquestion_data] = useState([]);
  const [question_id, setquestion_id] = useState(null);
  const [Category_data, setCategory_data] = useState([]);
  const [singledata, setsingledata] = useState([]);
  let { categoryid } = useParams();

  useEffect(() => {
    getcategory_data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getcategory_data = async () => {
    if (categoryid !== undefined) {
      var providerdata = localStorage.getItem("user");
      if (JSON.parse(providerdata)._id !== null) {
        await client
          .query({
            query: GET_QUESTION,
            variables: {
              _id: categoryid,
              user_id: JSON.parse(providerdata)._id,
            },
            fetchPolicy: "no-cache",
          })
          .then((result) => {
            if (
              result.data.get_ondemand_jobs[0]
                .get_ondemand_jobs_category_question.length !== 0
            ) {
              setquestion_data(
                result.data.get_ondemand_jobs[0]
                  .get_ondemand_jobs_category_question
              );
              setselectedpage("Question");
              setquestion_id(0);
              setsingledata(
                result.data.get_ondemand_jobs[0]
                  .get_ondemand_jobs_category_question[0]
              );
              setCategory_data(
                result.data.get_ondemand_jobs[0].get_category_names
              );
            }
          });
      }
    }
  };
  const selectQuestion = async (e) => {
    setselectedpage("Question");
    setquestion_id(e.target.id);
    var singledata = await question_data.filter((data, index) => {
      return index == e.target.id;
    });
    setsingledata(singledata[0]);
  };
  const getquestionid = async (e) => {
    setquestion_id(Number(e.target.id) + 1);
    var singledata = await question_data.filter((data, index) => {
      return index == Number(e.target.id) + 1;
    });
    if (singledata.length !== 0) {
      setsingledata(singledata[0]);
    } else {
      setselectedpage("Review");
    }
  };
  const Nextquestion = async (e) => {
    setquestion_id(Number(e) + 1);
    var singledata = await question_data.filter((data, index) => {
      return index == Number(e) + 1;
    });
    if (singledata.length !== 0) {
      setsingledata(singledata[0]);
    } else {
      setselectedpage("Review");
    }
  };
  const Editquestion = async (e) => {
    setquestion_id(Number(e));
    setselectedpage("Question");
    var singledata = await question_data.filter((data, index) => {
      return index == Number(e);
    });
    if (singledata.length !== 0) {
      setsingledata(singledata[0]);
    }
  };

  const Oldquestion = async () => {
    setselectedpage("Question");
    if (question_id != 0) {
      setquestion_id(question_id - 1);
      var singledata = await question_data.filter((data, index) => {
        return index == question_id - 1;
      });
      if (singledata.length !== 0) {
        setsingledata(singledata[0]);
      }
    } else {
      window.location.replace("/project");
    }
  };
  return (
    <>
      <div id="myDIV">
        <div className="ant-card bg-transparent h-100 ant-card-hoverable sidebardiv_que">
          <div>
            {selectedpage === "Location" ? (
              <div
                className="d-flex   f_26 sidebarheading livelick"
                onClick={() => {
                  setselectedpage("Location");
                }}
              >
                <div>Project Location</div>
              </div>
            ) : (
              <div
                className="d-flex   f_26 sidebarheading"
                onClick={() => {
                  setselectedpage("Location");
                }}
              >
                <div>Project Location</div>
              </div>
            )}
            <div
              onClick={() => {
                window.location.replace("/category");
              }}
              className="d-flex   f_26 sidebarheading"
            >
              Project Category
            </div>
            {question_data.length !== 0
              ? question_data.map((data, index) =>
                  question_id == index ? (
                    <div
                      className="d-flex   f_26 sidebarheading livelick"
                      key={index}
                      id={index}
                      onClick={selectQuestion}
                    >
                      <div id={index}>{data.question}</div>
                    </div>
                  ) : (
                    <div
                      className="d-flex   f_26 sidebarheading"
                      key={index}
                      id={index}
                      onClick={selectQuestion}
                    >
                      <div id={index}>{data.question}</div>
                    </div>
                  )
                )
              : null}

            {selectedpage === "Review" ? (
              <div
                className="d-flex   f_26 sidebarheading livelick"
                onClick={() => {
                  setselectedpage("Review");
                  setquestion_id(null);
                }}
              >
                <div>Review & Submit</div>
              </div>
            ) : (
              <div
                className="d-flex   f_26 sidebarheading"
                onClick={() => {
                  setselectedpage("Review");
                  setquestion_id(null);
                }}
              >
                <div>Review & Submit</div>
              </div>
            )}
          </div>
        </div>
        {selectedpage === "Location" ? (
          <Location />
        ) : selectedpage === "Question" ? (
          <Question
            Question_data={singledata}
            Category_data={Category_data}
            question_id={question_id}
            getquestionid={getquestionid}
            Nextquestion={Nextquestion}
            Oldquestion={Oldquestion}
          />
        ) : selectedpage === "Review" ? (
          <ProjectReview
            Editquestion={Editquestion}
            Oldquestion={Oldquestion}
          />
        ) : null}
      </div>
    </>
  );
};

export default Job;
