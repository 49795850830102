import React, { useState, useEffect } from 'react';
import find from 'lodash/find';
import size from 'lodash/size';
import { client } from "../../apollo";
import { GET_ADMIN } from '../../graphql/Admin/login'

const RoleView = (props) => {
    let data = localStorage.getItem('hokjighsasd')
    var user = {}
    if (data) {
        user = JSON.parse(window.atob(data))
    }
    const [PermissionAllow, setPermissionAllow] = useState(false);
    const [GizzyDeveloper, setGizzyDeveloper] = useState(false);

    useEffect(() => {
        setGizzyDeveloper(user['GizzyDeveloper'])
        if (user && user['full_permission_list'] && props.permission) {
            let data = find(user['full_permission_list'], { key: props.permission })
            if (size(data) || GizzyDeveloper) {
                setPermissionAllow(true)
            }
        } else {
            setPermissionAllow(true)
        }
    })
    if (PermissionAllow) {
        return (
            <div>{props.children}</div>
        )
    } else {
        return (<div></div>)
    }
};
export default RoleView;


export const RoleViewFunction = (permission) => {
    try {
        let data = localStorage.getItem('hokjighsasd')
        var user = {}
        if (data) {
            user = JSON.parse(window.atob(data))
        }
        let func_data = false
        let GizzyDeveloper = user['GizzyDeveloper'] || false
        if (user && user['full_permission_list'] && permission) {
            let data = find(user['full_permission_list'], { key: permission })
            if (size(data) || GizzyDeveloper) {
                func_data = true
            }
        }else{
            func_data = true
        }
        return func_data
    } catch (error) {
        return false
    }
};


export const isAuth = () => {
    return new Promise(resolve => {
      client.query({
        query: GET_ADMIN,
        variables: { _id: localStorage.getItem('admin_id') },
        fetchPolicy: 'no-cache',
      }).then(result => {
        let datas = JSON.stringify(result.data.get_admin)
        localStorage.setItem('hokjighsasd', window.btoa(datas))
        resolve(true);
      });
    })
  };