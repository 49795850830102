// HOC.js

import React, { Component } from 'react';
import { client } from "../../../apollo";
import { GET_WEB_HOME_SECTIONS } from '../../../graphql/Admin/HomeManage'
import size from 'lodash/size'

export default function HomeViewHoc(HocComponent, data) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                view_data: []
            };
        }
        componentDidMount() {
            this.get_view_data(data?.view_type)
        }
        get_view_data = async (type) => {
            let input_data = { view_device: "WEB" }
            if (type) {
                input_data['view_type'] = type
                input_data['country_code'] = 'KE'
            }
            const result = await client.query({
                query: GET_WEB_HOME_SECTIONS,
                variables: input_data,
                fetchPolicy: 'no-cache'
            })
            if (result.data && result.data.get_sections && result.data.get_sections.length) {
                let final_record = []
                result.data.get_sections.forEach(recordValue => {
                    if (recordValue.get_section_category && size(recordValue.get_section_category)) {
                        final_record.push(recordValue)
                    }
                })
                this.setState({ view_data: final_record },()=>{console.log('this.state from HomeViewHoc.js ', this.state)});
            }
        }
        render() {
            return (
                <HocComponent data={this.state.view_data} {...data} {...this.props} />
            );
        }
    }
}