import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense, setState } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import "./scss/Dashboard.scss";
import "./scss/template.scss";
import "./scss/user.scss";
import "./scss/bootstrap.min.css";
import { Layout, Skeleton } from "antd";
import { Switch, Redirect, Route, BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { client } from "./apollo";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloProviderHooks } from "@apollo/react-hooks";
import RoleView, {
  isAuth,
  RoleViewFunction,
} from "./component/Comman/roles_permission_view";
import AdminSider from "./component/Admin/Layout/AdminSider";
import AdminHeader from "./component/Admin/Layout/AdminHeader";

import HomePage from "./component/User/HomePage/HomePage";
import Category from "./component/User/CategoryView/Category";
import Job from "./component/User/CategoryView/Job";
import Myproject from "./component/User/ProjectDashboard/Myproject";
import JobView from "./component/User/ProjectDashboard/JobView";
import Jobaction from "./component/User/ProjectDashboard/Jobaction";
import Milestones from "./component/User/ProjectDashboard/Milestones";
import Createmilestone from "./component/User/ProjectDashboard/Createmilestone";
import Chat from "./component/User/ProjectDashboard/Chat";
import ProviderPage from "./component/User/ProjectDashboard/Providers";
import UserHeader from "./component/User/Layout/UserHeader";
import StaticPage from "./component/Comman/static_page";
import FAQ from "./component/User/About/Faq";
import HowLearnMore from "./component/User/About/HowLearnMore";
import NotFound from "./component/Comman/NotFound";
// const NotFound = React.lazy(() => import('./component/Comman/NotFound'));
const ViewProCategoryTag = React.lazy(() =>
  import("./component/Admin/ProCategoryTag/View")
);
const AddProCategoryTag = React.lazy(() =>
  import("./component/Admin/ProCategoryTag/AddTag")
);
const MainCategory = React.lazy(() =>
  import("./component/Admin/MainCategory/MainCategory")
);
const UpdateMainCategory = React.lazy(() =>
  import("./component/Admin/MainCategory/UpdateMainCategory")
);
const Provider = React.lazy(() =>
  import("./component/Admin/Provider/Provider")
);
const Add_Provider = React.lazy(() =>
  import("./component/Admin/Provider/Add_Provider")
);
const Provider_Verified = React.lazy(() =>
  import("./component/Admin/Provider/Provider_Verified")
);
const Certificate = React.lazy(() =>
  import("./component/Admin/Certificate/Certificate")
);
const OrganizationIndustry = React.lazy(() =>
  import("./component/Admin/OrganizationIndustry/OrganizationIndustry")
);
const User = React.lazy(() => import("./component/Admin/User/User"));
const Add_User = React.lazy(() => import("./component/Admin/User/Add_User"));
const Static = React.lazy(() => import("./component/Admin/Static/Static"));
const Add_Static = React.lazy(() =>
  import("./component/Admin/Static/Add_static")
);
const Contract = React.lazy(() =>
  import("./component/Admin/Contract/Contract")
);
const OnDemand = React.lazy(() =>
  import("./component/Admin/OnDemand/OnDemand")
);
const OndemandDetail = React.lazy(() =>
  import("./component/Admin/OnDemand/OnDemandDetail")
);
const OpsTest = React.lazy(() => import("./component/Admin/OnDemand/OpsTest"));
const ContractDetail = React.lazy(() =>
  import("./component/Admin/Contract/ContractDetail")
);
const Roles = React.lazy(() => import("./component/Admin/Roles/Roles"));
const Add_Admin = React.lazy(() => import("./component/Admin/Roles/Add_Admin"));
const AdminRoles = React.lazy(() =>
  import("./component/Admin/Roles/Add_Roles")
);
const Company = React.lazy(() => import("./component/Admin/Company/Company"));
const Add_Company = React.lazy(() =>
  import("./component/Admin/Company/Add_Company")
);
const CompanyWorkerAdmin = React.lazy(() =>
  import("./component/Admin/Company/CompanyWorkerAdmin")
);
const ContractBooking = React.lazy(() =>
  import("./component/User/Book/contract/ContractBooking")
);
const ContractUserDetail = React.lazy(() =>
  import("./component/User/Book/contract/view/ContractUserDetail")
);
const User_Login = React.lazy(() =>
  import("./component/User/Login/User_Login")
);
const Profile_Page = React.lazy(() =>
  import("./component/User/Profile/Profile")
);
const Bookings_Page = React.lazy(() =>
  import("./component/User/Book/Bookings")
);
const Description_Page = React.lazy(() =>
  import("./component/User/Book/Description")
);
const Payouts = React.lazy(() => import("./component/Admin/Payouts/Payouts"));
const Review = React.lazy(() => import("./component/Admin/Review/Review"));
const Settings = React.lazy(() => import("./component/Admin/Setting/Setting"));
const HomeManage = React.lazy(() =>
  import("./component/Admin/HomePage/HomeManage")
);
const HomeTheme = React.lazy(() =>
  import("./component/Admin/HomePage/HomeTheme")
);
const HomeManageEdit = React.lazy(() =>
  import("./component/Admin/HomePage/HomeManageEdit")
);
const Email_Login = React.lazy(() =>
  import("./component/User/Login/Email_Login")
);
const Invoice = React.lazy(() => import("./component/Admin/Invoice/invoice"));
const NotAccess = React.lazy(() => import("./component/Comman/NotAccess"));
const ComingSoon = React.lazy(() =>
  import("./component/User/HomePage/ComingSoon")
);

const provider_detail = React.lazy(() =>
  import("./component/User/Provider/Provider_Details")
);
const provider_earnings = React.lazy(() =>
  import("./component/User/Provider/Provider_Earns")
);
const Booking_Detail = React.lazy(() =>
  import("./component/User/Provider/Booking_Detail")
);
const Provider_Email_Login = React.lazy(() =>
  import("./component/User/Login/Provider_Email_Login")
);
const Provider_Login = React.lazy(() =>
  import("./component/User/Login/Provider_Login")
);
const { ConfrimPassword } = React.lazy(() =>
  import("./component/User/Login/ConfrimPassword")
);

const UserFooter = React.lazy(() =>
  import("./component/User/Layout/UserFooter")
);
const Currency = React.lazy(() =>
  import("./component/Admin/Currency/Currency")
);
const AddCurrency = React.lazy(() =>
  import("./component/Admin/Currency/AddCurrency")
);
const LoginPage = React.lazy(() =>
  import("./component/Admin/Layout/LoginPage")
);
const Dashboard = React.lazy(() =>
  import("./component/Admin/Dashboard/Dashboard")
);
const UserReport = React.lazy(() =>
  import("./component/Admin/Reports/UserReport")
);
const ProviderReport = React.lazy(() =>
  import("./component/Admin/Reports/ProviderReport")
);
const CompanyReport = React.lazy(() =>
  import("./component/Admin/Reports/CompanyReport")
);
const CompanyTypesReport = React.lazy(() =>
  import("./component/Admin/Reports/CompanyTypesReport")
);
const MainCategoryReport = React.lazy(() =>
  import("./component/Admin/Reports/MainCategoryReport")
);
const ProTagCategoryReport = React.lazy(() =>
  import("./component/Admin/Reports/ProTagCategoryReport")
);
const OndemandReport = React.lazy(() =>
  import("./component/Admin/Reports/OndemandReport")
);
const Subscriptions = React.lazy(() =>
  import("./component/Admin/Subscriptions/Subscriptions")
);
const AddSubscriptionPlan = React.lazy(() =>
  import("./component/Admin/Subscriptions/AddSubscriptionPlan")
);
const Subscribers = React.lazy(() =>
  import("./component/Admin/Subscriptions/Subscribers/Subscribers")
);
const SubscribersHistory = React.lazy(() =>
  import("./component/Admin/Subscriptions/Subscribers/SubscribersHistory")
);

const { Content } = Layout;

function PrivateRoute({ permission, component: Component, ...rest }) {
  const [permission_condition, set_permission_condition] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [collapsed, set_collapsed] = React.useState(false);

  React.useEffect(() => {
    isAuth().then((res) => {
      let per_data = RoleViewFunction(permission);
      set_permission_condition(per_data);
      setLoading(false);
    });
  });
  return (
    <>
      {loading ? (
        <div className="main_loader d-flex justify-content-center">
          <img src="/load.gif" alt="" loading="lazy" />
        </div>
      ) : (
        <Route
          {...rest}
          render={(props) => {
            if (localStorage.getItem("adminLogin") === "success") {
              if (permission_condition) {
                return (
                  <>
                    <Layout style={{ height: "100vh" }}>
                      <AdminSider update_collapsed={collapsed} />
                      <Layout>
                        <AdminHeader />
                        <Content
                          style={{ background: "#fff", minHeight: "auto" }}
                          className="main_frame"
                        >
                          <Component {...props} />
                        </Content>
                      </Layout>
                    </Layout>
                  </>
                );
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/notaccess",
                    }}
                  />
                );
              }
            } else {
              return (
                <Redirect
                  to={{
                    pathname: "/admin",
                  }}
                />
              );
            }
          }}
        />
      )}
    </>
  );
}

function UserRoute({ component: Component, isFooter, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("userLogin") === "success" ? (
          <>
            <Layout className="white">
              {/* <Content className="px-md-5"> */}
              <UserHeader />
              <Component {...props} />
              {/* {isFooter && (
                  <Suspense fallback={<Skeleton active />}>
                    <UserFooter />
                  </Suspense>
                )} */}
              {/* </Content> */}
            </Layout>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}
function ProviderRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("providerLogin") === "success" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/provider_login",
            }}
          />
        )
      }
    />
  );
}

function UnAuthRoute({
  component: Component,
  isHeader,
  no_content_adding,
  isFooter,
  login = "",
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Layout className="white">
            {isHeader && <UserHeader />}
            <Content className={no_content_adding ? "" : "px-md-5"}>
              <Component status={login} {...props} />
              {isFooter && (
                <Suspense fallback={<Skeleton active />}>
                  <UserFooter />
                </Suspense>
              )}
            </Content>
          </Layout>
        </>
      )}
    />
  );
}

export function App() {
  const [grantAccess, setGrantAccess] = React.useState("");
  console.log("App component state", grantAccess);
  const onLogin = () => {
    setGrantAccess("success");
  };

  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <ApolloProviderHooks client={client}>
          <Switch>
            <UnAuthRoute
              no_content_adding={true}
              isHeader={true}
              isFooter={true}
              exact
              path="/"
              component={HomePage}
            />
            <UnAuthRoute exact path="/static_page/:id" component={StaticPage} />
            <UnAuthRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/howlearnmore"
              component={HowLearnMore}
            />

            <UserRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/project/:categoryid"
              component={Job}
            />
            <UserRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/project"
              component={Job}
            />
            <UserRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/myproject"
              component={Myproject}
            />
            <UserRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/jobview/:jobid"
              component={JobView}
            />
            <UserRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/jobaction/:jobid"
              component={Jobaction}
            />
            <UserRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/milestones/:jobid"
              component={Milestones}
            />
            <UserRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/createmilestone/:jobid"
              component={Createmilestone}
            />
            <UserRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/inbox"
              component={Chat}
            />
            <UserRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/inbox/:booking_id"
              component={Chat}
            />
            <UserRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/provider/:jobid"
              component={ProviderPage}
            />
            <UserRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/category"
              component={Category}
            />
            <UserRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/category/:categoryid"
              component={Category}
            />
            <UnAuthRoute
              isHeader={true}
              isFooter={true}
              exact
              path="/faq"
              component={FAQ}
            />
            <Suspense
              fallback={
                <div>
                  <p
                    className="container mt-2"
                    style={{
                      backgroundColor: "#f5f5f5",
                      width: "100%",
                      height: "30px",
                    }}
                  ></p>
                  <div className="d-flex container mt-2 p-0">
                    <p
                      className="w-50 mr-2"
                      style={{
                        backgroundColor: "#f5f5f5",
                        width: "100%",
                        height: "50vh",
                      }}
                    ></p>
                    <p
                      className="w-50 ml-2"
                      style={{
                        backgroundColor: "#f5f5f5",
                        width: "100%",
                        height: "50vh",
                      }}
                    ></p>
                  </div>
                  <p
                    className="container mt-2"
                    style={{
                      backgroundColor: "#f5f5f5",
                      width: "100%",
                      height: "50vh",
                    }}
                  ></p>
                </div>
              }
            >
              <UnAuthRoute
                path="/admin-booking-invoice/:type/:id"
                component={Invoice}
                exact
              />
              <UnAuthRoute
                exact
                path="/admin"
                component={LoginPage}
                login={onLogin}
              />
              <UnAuthRoute exact path="/login" component={User_Login} />
              <UnAuthRoute
                isHeader={true}
                isFooter={true}
                exact
                path="/coming_soon"
                component={ComingSoon}
              />
              <UnAuthRoute
                exact
                path="/Confrim_password/:id"
                component={ConfrimPassword}
              />
              <UnAuthRoute exact path="/signup" component={Email_Login} />
              <UnAuthRoute
                exact
                path="/provider_login"
                component={Provider_Login}
              />
              <UnAuthRoute
                exact
                path="/provider_signup"
                component={Provider_Email_Login}
              />
              <UnAuthRoute exact path="/notaccess" component={NotAccess} />

              <PrivateRoute
                path="/admin-dashboard"
                component={Dashboard}
                exact
              />
              {/* Reports */}
              <PrivateRoute
                path="/admin-report/admin-user-report"
                component={UserReport}
                exact
              />
              <PrivateRoute
                path="/admin-report/admin-provider-report"
                component={ProviderReport}
                exact
              />
              <PrivateRoute
                path="/admin-report/admin-company-report"
                component={CompanyReport}
                exact
              />
              <PrivateRoute
                path="/admin-report/admin-companyTypes-report"
                component={CompanyTypesReport}
                exact
              />
              <PrivateRoute
                path="/admin-report/admin-category-report"
                component={MainCategoryReport}
                exact
              />
              <PrivateRoute
                path="/admin-report/admin-pro-category-report"
                component={ProTagCategoryReport}
                exact
              />
              <PrivateRoute
                path="/admin-report/admin-projects-report"
                component={OndemandReport}
                exact
              />
              <PrivateRoute
                path="/admin-report/admin-contract-report"
                component={UserReport}
                exact
              />
              {/* user */}
              <PrivateRoute
                permission="add_user"
                path="/admin-user/add"
                component={Add_User}
                exact
              />
              <PrivateRoute
                permission="edit_user"
                path="/admin-user/add/:id"
                component={Add_User}
                exact
              />
              <PrivateRoute
                permission="view_user"
                path="/admin-user"
                component={User}
                exact
              />
              {/* provider */}
              <PrivateRoute
                permission="add_provider"
                exact
                path="/admin-provider/add"
                component={Add_Provider}
              />
              <PrivateRoute
                permission="edit_provider"
                path="/admin-provider/add/:id"
                component={Add_Provider}
                exact
              />
              <PrivateRoute
                permission="view_provider"
                path="/admin-provider"
                component={Provider}
                exact
              />
              <PrivateRoute
                permission="approve_provider"
                path="/admin-provider/view/:id"
                component={Provider_Verified}
                exact
              />
              {/* category */}
              <Suspense
                fallback={
                  <div>
                    <p
                      className="container mt-2"
                      style={{
                        backgroundColor: "#f5f5f5",
                        width: "100%",
                        height: "30px",
                      }}
                    ></p>
                    <div className="d-flex container mt-2 p-0">
                      <p
                        className="w-50 mr-2"
                        style={{
                          backgroundColor: "#f5f5f5",
                          width: "100%",
                          height: "50vh",
                        }}
                      ></p>
                      <p
                        className="w-50 ml-2"
                        style={{
                          backgroundColor: "#f5f5f5",
                          width: "100%",
                          height: "50vh",
                        }}
                      ></p>
                    </div>
                    <p
                      className="container mt-2"
                      style={{
                        backgroundColor: "#f5f5f5",
                        width: "100%",
                        height: "50vh",
                      }}
                    ></p>
                  </div>
                }
              >
                <PrivateRoute
                  permission="view_category"
                  exact
                  path="/admin-maincategory/add/:pid/:cid"
                  component={UpdateMainCategory}
                />
                <PrivateRoute
                  permission="view_category"
                  exact
                  path="/admin-maincategory/add/:pid"
                  component={UpdateMainCategory}
                />
                <PrivateRoute
                  permission="view_category"
                  exact
                  path="/admin-maincategory/add"
                  component={UpdateMainCategory}
                />
                <PrivateRoute
                  permission="view_category"
                  exact
                  path="/admin-category_tag/add/:_id"
                  component={AddProCategoryTag}
                />
                <PrivateRoute
                  permission="view_category"
                  exact
                  path="/admin-category_tag/add"
                  component={AddProCategoryTag}
                />
                <PrivateRoute
                  permission="view_category"
                  exact
                  path="/admin-category_tag"
                  component={ViewProCategoryTag}
                />
              </Suspense>
              <PrivateRoute
                permission="view_category"
                exact
                path="/admin-maincategory"
                component={MainCategory}
              />

              {/* payout */}
              <PrivateRoute
                permission="view_payout"
                exact
                path="/admin-payouts"
                component={Payouts}
              />
              {/* review */}
              <PrivateRoute
                permission="view_review"
                path="/admin-review"
                component={Review}
                exact
              />
              <PrivateRoute
                permission="view_certificate"
                path="/admin-certificate"
                component={Certificate}
                exact
              />
              <PrivateRoute
                permission="view_organization_industry"
                path="/admin-organization_industry"
                component={OrganizationIndustry}
                exact
              />
              {/* static */}
              <PrivateRoute
                permission="add_staticContent"
                path="/admin-static/add"
                component={Add_Static}
                exact
              />
              <PrivateRoute
                permission="edit_staticContent"
                path="/admin-static/add/:id"
                component={Add_Static}
                exact
              />
              <PrivateRoute
                permission="view_staticContent"
                path="/admin-static"
                component={Static}
                exact
              />
              {/* setting */}
              <PrivateRoute path="/admin-settings" component={Settings} exact />
              {/* subscriptions */}
              <PrivateRoute
                permission="view_subscriptions_plan"
                path="/admin-subscriptions"
                component={Subscriptions}
                exact
              />
              <PrivateRoute
                permission="add_subscriptions_location"
                path="/admin-subscriptions/add"
                component={AddSubscriptionPlan}
                exact
              />
              <PrivateRoute
                permission="edit_subscriptions_location"
                path="/admin-subscriptions/add/:id"
                component={AddSubscriptionPlan}
                exact
              />
              <PrivateRoute
                permission="view_subscriber"
                path="/admin-subscriber"
                component={Subscribers}
                exact
              />
              <PrivateRoute
                permission="view_subscriber_history"
                path="/admin-subscriber/view_history/:_id"
                component={SubscribersHistory}
                exact
              />
              {/* currency */}
              <PrivateRoute
                permission="add_currency"
                path="/admin-currency/add"
                component={AddCurrency}
                exact
              />
              <PrivateRoute
                permission="edit_currency"
                path="/admin-currency/add/:id"
                component={AddCurrency}
                exact
              />
              <PrivateRoute
                permission="view_currency"
                path="/admin-currency"
                component={Currency}
                exact
              />
              {/* HomeManage */}
              <PrivateRoute
                permission=""
                path="/admin-home-theme"
                component={HomeTheme}
                exact
              />
              <PrivateRoute
                permission=""
                path="/admin-home-manage"
                component={HomeManage}
                exact
              />
              <PrivateRoute
                permission=""
                path="/admin-home-manage/view/:_id"
                component={HomeManage}
                exact
              />
              <PrivateRoute
                permission=""
                path="/admin-home-manage/add/:_id"
                component={HomeManageEdit}
                exact
              />
              <PrivateRoute
                permission=""
                path="/admin-home-manage/edit/:_id/:_theme_id"
                component={HomeManageEdit}
                exact
              />
              {/* <PrivateRoute
                permission=""
                path="/admin-home-manage/add/new/:_id"
                component={HomeManageEdit}
                exact
              /> */}
              {/* company */}
              <PrivateRoute
                permission="view_company"
                path="/admin-company"
                component={Company}
                exact
              />
              <PrivateRoute
                permission="add_company"
                path="/admin-company/add/:user_id"
                component={Add_Company}
                exact
              />
              <PrivateRoute
                permission="edit_company"
                path="/admin-company/add/:user_id/:company_id"
                component={Add_Company}
                exact
              />
              <PrivateRoute
                permission="view_company_worker"
                path="/admin-company-worker-detail"
                component={CompanyWorkerAdmin}
                exact
              />
              {/* contract */}
              <PrivateRoute
                permission="view_contract_detail"
                exact
                path="/admin-contract/view/:id"
                component={ContractDetail}
              />
              <PrivateRoute
                permission="view_contract_detail"
                exact
                path="/contract/view/:id"
                component={ContractUserDetail}
              />
              <PrivateRoute
                permission="view_contract"
                exact
                path="/admin-contract"
                component={Contract}
              />
              <PrivateRoute
                permission="view_contract_detail"
                exact
                path="/admin-project/view/:id"
                component={OndemandDetail}
              />
              <PrivateRoute
                permission="view_contract"
                exact
                path="/admin-projects"
                component={OnDemand}
              />
              <PrivateRoute
                permission="view_contract"
                exact
                path="/admin-ops"
                component={OpsTest}
              />
              {/* roles */}
              <PrivateRoute
                permission="view_roles"
                path="/admin-roles"
                component={Roles}
                exact
              />
              <PrivateRoute
                permission="add_roles"
                path="/admin-roles/add"
                component={AdminRoles}
                exact
              />
              <PrivateRoute
                permission="edit_roles"
                path="/admin-roles/add/:id"
                component={AdminRoles}
                exact
              />
              <PrivateRoute
                permission="add_admin"
                path="/admin-admin/add"
                component={Add_Admin}
                exact
              />
              <PrivateRoute
                permission="edit_admin"
                path="/admin-admin/add/:id"
                component={Add_Admin}
                exact
              />
              {/* <PrivateRoute exact path="/contract_booking/:id" component={ContractBooking} /> */}
              {/* pro urls */}
              <ProviderRoute
                exact
                path="/provider_detail"
                component={provider_detail}
              />
              <ProviderRoute
                exact
                path="/provider_earnings"
                component={provider_earnings}
              />
              <ProviderRoute
                exact
                path="/provider-booking-detail"
                component={Booking_Detail}
              />

              <Suspense
                fallback={
                  <div>
                    <p
                      className="container mt-2"
                      style={{
                        backgroundColor: "#f5f5f5",
                        width: "100%",
                        height: "30px",
                      }}
                    ></p>
                    <div className="d-flex container mt-2 p-0">
                      <p
                        className="w-50 mr-2"
                        style={{
                          backgroundColor: "#f5f5f5",
                          width: "100%",
                          height: "50vh",
                        }}
                      ></p>
                      <p
                        className="w-50 ml-2"
                        style={{
                          backgroundColor: "#f5f5f5",
                          width: "100%",
                          height: "50vh",
                        }}
                      ></p>
                    </div>
                    <p
                      className="container mt-2"
                      style={{
                        backgroundColor: "#f5f5f5",
                        width: "100%",
                        height: "50vh",
                      }}
                    ></p>
                  </div>
                }
              >
                <UserRoute exact path="/profile" component={Profile_Page} />
              </Suspense>
              <UserRoute
                isFooter={false}
                exact
                path="/description/:id"
                component={Description_Page}
              />
              <UserRoute
                isFooter={false}
                exact
                path="/bookings"
                component={Bookings_Page}
              />
              <UserRoute
                exact
                path="/contract_booking/:id"
                component={ContractBooking}
              />
              <UserRoute
                isHeader={true}
                exact
                path="/contract/user_view/:id"
                component={ContractUserDetail}
              />
            </Suspense>
            <UnAuthRoute exact component={NotFound} />
          </Switch>
        </ApolloProviderHooks>
      </ApolloProvider>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
