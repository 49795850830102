import gql from "graphql-tag";

export const ADMIN_LOGIN = gql`
      mutation ADMINLOGIN($email:String,$password:String) {
            adminLogin(email:$email,password:$password){ 
                  info
                  email
                  _id
                  password
                  name
                  GizzyDeveloper
                  full_permission_list{
                        name
                        _id
                        key
                  }
            }
      }
`;

export const GET_ADMIN = gql`
query getadmin($_id:ID) {
      get_admin(_id:$_id) {
      _id
      email
      password
      name
      status
      msg
      GizzyDeveloper
      full_permission_list{
            name
            _id
            key
      }
    }
}
`;

