import gql from "graphql-tag";

export const ADD_ADDRESS = gql`
  mutation AddAddressmutation(
    $option: Int
    $user_id: String
    $title: String
    $flat_no: String
    $landmark: String
    $address: String
    $location_code: String
    $lat: String
    $lng: String
    $company_id: ID
    $user_type: String
  ) {
    modified_address(
      option: $option
      user_id: $user_id
      title: $title
      flat_no: $flat_no
      landmark: $landmark
      address: $address
      lat: $lat
      lng: $lng
      location_code: $location_code
      company_id: $company_id
      user_type: $user_type
    ) {
      msg
      status
    }
  }
`;

export const GET_ADDRESS = gql`
  query GetAdressQuery($user_id: ID) {
    user_address(user_id: $user_id) {
      _id
      title
      flat_no
      landmark
      address
      country_code
      location_code
      lat
      lng
      company_id
    }
  }
`;
