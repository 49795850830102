import React, { useState, Suspense, useContext } from "react";
import { Typography, Row, Col, Modal, Button, Skeleton } from "antd";
import { HomeContext } from "../../../context/Location";
const BannerLocationSearch = React.lazy(() => import("./BannerLocationSearch"));
const { Paragraph, Text } = Typography;
let LocalData = {
  title1: "Your Service in",
  title2: "your home of convenience",
};
const PlaceComplete = React.lazy(() => import("../../../Comman/PlaceComplete"));
const BannerText = () => {
  const home_context = useContext(HomeContext);
  const [LocationModal, setLocationModal] = useState(false);
  const [home_page_city, set_home_page_city] = useState("Your Job Location");
  const [address, set_address] = useState("");
  const [center, set_center] = useState([]);
  const PlaceCompleteFunction = async (data) => {
    set_home_page_city(data?.home_page_city);
    set_center(data?.center);
    home_context.set_home_page_location(data);
  };
  return (
    <>
      <Row>
        <Col md={24} className="">
          <h6 className="text-white maiandra_font bold">{LocalData.title1}</h6>
          <h6 className="text-white maiandra_font mb-2">{LocalData.title2}</h6>
          <p className="mb-4 text-white mt-3">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
            pellentesque est posuere quam tempus, et imperdiet lacus tempus.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={24} md={8} className="pr-0 pr-md-2">
          <Button
            icon="environment"
            className="lato_font alter_jiffy_btn overflow-hidden px-1 jiffy_btn normal_font_size w-100"
            onClick={() => setLocationModal(true)}
          >
            {home_page_city}
          </Button>
        </Col>
        <Col sm={24} md={6}></Col>
        <Col sm={24} md={10} className="my-3 my-md-0">
          <Suspense fallback={<Skeleton active />}>
            <BannerLocationSearch />
          </Suspense>
        </Col>
      </Row>
      <Modal
        title="Choose Your Location"
        style={{ borderRadius: "2em", top: 20 }}
        className="home_modal new_modal"
        visible={LocationModal}
        onOk={() => setLocationModal(false)}
        onCancel={() => setLocationModal(false)}
      >
        <Suspense fallback={<Skeleton active />}>
          <PlaceComplete PlaceCompleteFunction={PlaceCompleteFunction} />
        </Suspense>
      </Modal>
    </>
  );
};
export default BannerText;
