import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "antd/lib/layout";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Tag from "antd/lib/tag";
import Button from "antd/lib/button";
import Avatar from "antd/lib/avatar";
import Icon from "antd/lib/icon";
import Menu from "antd/lib/menu";
import Dropdown from "antd/lib/dropdown";
import main from "../../../image/Gigzzy.webp";
import useReactRouter from "use-react-router";
import { GET_SETTING } from "../../../graphql/Admin/static";
import useMobileDetect from "use-mobile-detect-hook";

const { Header } = Layout;

const UserHeader = () => {
  const location = useLocation();
  const detectMobile = useMobileDetect();
  const { history } = useReactRouter();

  const logout = () => {
    if (localStorage.getItem("userLogin") === "success") {
      localStorage.removeItem("userLogin");
      localStorage.removeItem("user");
      history.push("/login");
    }
  };
  const open_new_tab = (data) => {
    const url = `${data}`;
    console.log(url);
    window.open(url);
  };

  const menu = (
    <Menu>
      <Menu.Item
        className={
          localStorage.getItem("userLogin") === "success"
            ? "d-flex align-items-center px-3"
            : "d-none"
        }
        onClick={() => {
          history.push("/");
        }}
      >
        <Icon type="home" />
        Home
      </Menu.Item>
      <Menu.Item
        className={
          localStorage.getItem("userLogin") === "success"
            ? "d-flex align-items-center px-3"
            : "d-none"
        }
        onClick={() => {
          history.push("/profile");
        }}
      >
        <Icon type="user" />
        Profile
      </Menu.Item>
      <Menu.Item
        className={
          localStorage.getItem("userLogin") === "success"
            ? "d-flex align-items-center px-3"
            : "d-none"
        }
        onClick={() => {
          history.push("/bookings");
        }}
      >
        <Icon type="book" />
        Bookings
      </Menu.Item>
      <Menu.Item
        className={
          localStorage.getItem("userLogin") === "success"
            ? "d-flex align-items-center px-3"
            : "d-none"
        }
        onClick={() => {
          history.push("/myproject");
        }}
      >
        <Icon type="align-center" />
        All Projects
      </Menu.Item>
      <Menu.Item
        className={
          localStorage.getItem("userLogin") === "success"
            ? "d-flex align-items-center px-3"
            : "d-none"
        }
        onClick={() => {
          history.push("/inbox");
        }}
      >
        <Icon type="align-center" />
        Inbox
      </Menu.Item>
      <Menu.Item
        className={
          localStorage.getItem("userLogin") === "success"
            ? "d-flex align-items-center px-3"
            : "d-none"
        }
        onClick={() => {
          open_new_tab("/static_page/about_us");
        }}
      >
        <Icon type="setting" />
        Help & Support
      </Menu.Item>
      <Menu.Item
        className={
          localStorage.getItem("userLogin") === "success"
            ? "d-flex align-items-center px-3"
            : "d-none"
        }
        onClick={() => {
          open_new_tab("/static_page/terms");
        }}
      >
        <Icon type="info-circle" />
        Terms & Conditions
      </Menu.Item>
      <Menu.Item
        className={
          localStorage.getItem("userLogin") === "success"
            ? "d-flex align-items-center px-3"
            : "d-none"
        }
        onClick={logout}
      >
        <Icon type="logout" />
        Logout
      </Menu.Item>
    </Menu>
  );

  const without_login_menu = (
    <Menu>
      <Menu.Item
        className="d-flex align-items-center px-3"
        onClick={() => {
          history.push("/howlearnmore");
        }}
      >
        <Icon type="question-circle" />
        How it works
      </Menu.Item>
      <Menu.Item
        className="d-flex align-items-center px-3"
        onClick={() => {
          history.push("/login");
        }}
      >
        <Icon type="login" />
        Customer Login
      </Menu.Item>
      <Menu.Divider />
      {/* <Menu.Item
        className="d-flex align-items-center px-3"
        onClick={() => {
          open_new_tab("/provider_login");
        }}
      >
        <Icon type="shop" theme="twoTone" twoToneColor="#52c41a" />
        <span className="primary_color">Service Provider Login</span>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <div>
      {/* <div className="header_bg text-center">
        <p className="m-0 small py-2">
          A note about home projects during Coronavirus (COVID-19). Learn More
        </p>
      </div> */}
      <Header className="white user_header">
        <Row className="px-2 px-md-5">
          <Col>
            <img
              src={main}
              alt={"Jiffy"}
              className="object_fit cursor_point lazyload pb-3"
              loading="lazy"
              onClick={() => {
                history.push("/");
              }}
            />
            {/* <div className="float-right cursor_point tonavbar"> */}
            {localStorage.getItem("userLogin") === "success" ? (
              <>
                {detectMobile.isMobile() === false ? (
                  <div className="float-right cursor_point tonavbar">
                    <button
                      onClick={() => {
                        history.push("/");
                      }}
                      className="header_btn p-0 hvr-underline-from-center mr-5"
                    >
                      Home
                    </button>
                    <button
                      onClick={() => {
                        history.push("/project");
                      }}
                      className="header_btn p-0 hvr-underline-from-center mr-5"
                    >
                      Post a Project
                    </button>

                    <button
                      onClick={() => {
                        history.push("/myproject");
                      }}
                      className="header_btn p-0 hvr-underline-from-center mr-5"
                    >
                      My Projects
                    </button>
                    <button
                      onClick={() => {
                        history.push("/inbox");
                      }}
                      className="header_btn p-0 hvr-underline-from-center mr-5"
                    >
                      Inbox
                    </button>
                    <button
                      onClick={() => {
                        history.push("/profile");
                      }}
                      className="header_btn p-0 hvr-underline-from-center mr-5"
                    >
                      Profile
                    </button>
                    <button
                      onClick={logout}
                      className="header_btn p-0 hvr-underline-from-center mr-5"
                    >
                      Logout
                    </button>
                    <Dropdown overlay={menu} placement="bottomRight">
                      <Avatar
                        shape="circle"
                        className="ant-dropdown-link avatar_shadow"
                        icon={
                          <Icon
                            type="user"
                            style={{ verticalAlign: "baseline" }}
                          />
                        }
                        src={
                          JSON.parse(localStorage.getItem("user"))
                            ? JSON.parse(localStorage.getItem("user")).img_url
                            : ""
                        }
                      />
                    </Dropdown>
                  </div>
                ) : (
                  <div className="float-right cursor_point tonavbar">
                    <button
                      onClick={() => {
                        history.push("/project");
                      }}
                      className="header_btn p-0 hvr-underline-from-center mx-4"
                    >
                      Post a Project
                    </button>

                    <Dropdown overlay={menu} placement="bottomRight">
                      <Avatar
                        shape="circle"
                        className="ant-dropdown-link avatar_shadow"
                        icon={
                          <Icon
                            type="user"
                            style={{ verticalAlign: "baseline" }}
                          />
                        }
                        src={
                          JSON.parse(localStorage.getItem("user"))
                            ? JSON.parse(localStorage.getItem("user")).img_url
                            : ""
                        }
                      />
                    </Dropdown>
                  </div>
                )}
              </>
            ) : detectMobile.isMobile() ? (
              <div className="float-right cursor_point tonavbar">
                <Dropdown overlay={without_login_menu} placement="bottomRight">
                  <Avatar
                    shape="circle"
                    className="ant-dropdown-link avatar_shadow"
                    icon={
                      <Icon
                        type="menu-unfold"
                        style={{ verticalAlign: "baseline" }}
                      />
                    }
                  />
                </Dropdown>
              </div>
            ) : (
              <>
                <div className="float-right cursor_point tonavbar">
                  <div>
                    <button
                      onClick={() => {
                        history.push("/howlearnmore");
                      }}
                      className="header_btn p-0 hvr-underline-from-center mx-4"
                    >
                      How it works
                    </button>
                    <button
                      onClick={() => {
                        history.push("/login");
                      }}
                      className="header_btn p-0 hvr-underline-from-center mx-4"
                    >
                      Customer Login
                    </button>
                    {/* <button
                      onClick={() => {
                        open_new_tab("/provider_login");
                      }}
                      className="header_btn p-0 hvr-underline-from-center mx-4"
                    >
                      Service Provider Login
                    </button> */}
                  </div>
                </div>
              </>
            )}
            {/* </div> */}
          </Col>
        </Row>
      </Header>
    </div>
  );
};

export default UserHeader;
