import React from "react";
import { Menu, Icon } from 'antd';
import { BrowserRouter as Link, withRouter } from "react-router-dom";
import { Drawer } from 'antd';
import main from '../../../image/Gigzzy.webp';
import '../../../scss/template.scss';
import { menu_data } from './menu_data'
import split from "lodash/split";
import  { RoleViewFunction } from '../../Comman/roles_permission_view'
const { SubMenu } = Menu;

class DrawerForm extends React.Component {
  state = { visible: false };
  componentDidMount() {
    this.setState({
      mainKey: [`/${split(this.props.location.pathname, '/')[1]}`]
    })
    let jobsarray = ['jobs', 'contract', 'booking', 'request']
    let categorysarray = ['category', 'subcategory',]
    let subscriptionsarray = ['subscriptions', 'subscriber']

    const jobsarrayresult = jobsarray.some(term => this.props.location.pathname.includes(term))
    const categorysarrayresult = categorysarray.some(term => this.props.location.pathname.includes(term))
    const subscriptionsarrayresult = subscriptionsarray.some(term => this.props.location.pathname.includes(term))


    if (jobsarrayresult) {
      this.setState({
        openKeys: ['jobs']
      })
    } else if (categorysarrayresult) {
      this.setState({
        openKeys: ['category']
      })
    } else if (subscriptionsarrayresult) {
      this.setState({
        openKeys: ['subscriptions']
      })
    }
  }

  onSelect = (e) => {
    this.setState({
      openKeys: e
    })
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const icon_style = {
      fontSize: "2.5em",
      color: "white",
    }
    return (
      <div>
        <Icon type="menu-unfold" className="d-inline d-md-none" style={icon_style} onClick={this.showDrawer} />
        <Drawer
          width={200}
          onClose={this.onClose}
          visible={this.state.visible}
          closable={false}
          placement="left"
        >
          <div className="logo d-flex justify-content-around">
            <img src={main} alt={'gigzzy'} className='object_fit' />
          </div>
          <Menu theme="dark" selectedKeys={[this.props.location.pathname]} mode="inline">
            {menu_data && menu_data.map((menu_item) => {
              if (menu_item.menu_type) {
                return (
                  <SubMenu key={menu_item.menu_type_key} title={
                    <div className="d-flex align-items-center">
                      <Icon type={menu_item.menu_type_icon} /><div>{menu_item.menu_type_title} </div></div>} >
                    {menu_item.sub_menu_data.map(sub_menu_item => {
                      let permission = RoleViewFunction(sub_menu_item.permission)
                      if (permission) {
                        return (
                          <Menu.Item key={sub_menu_item.key}  onClick={() => { this.props.history.push(sub_menu_item.link) }}>
                            <Icon type={sub_menu_item.icon} />
                            <span>{sub_menu_item.title}</span>
                            <Link to={sub_menu_item.link} />
                          </Menu.Item>
                        )
                      }
                    })}
                  </SubMenu>
                )
              } else {
                let permission = RoleViewFunction(menu_item.permission)
                if (permission) {
                  return (
                    <Menu.Item key={menu_item.key} onClick={() => { this.props.history.push(menu_item.link) }}>
                      <Icon type={menu_item.icon} />
                      <span>{menu_item.title}</span>
                      <Link to={menu_item.link} />
                    </Menu.Item>
                  )
                }
              }
            })}
          </Menu>
        </Drawer>
      </div>
    );
  }
}

export default withRouter(DrawerForm);