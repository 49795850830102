import gql from "graphql-tag";

export const GET_ONDEMAND_PAGINATION = gql`
  query GETONDEMANDPAGINATION(
    $limit: Int
    $page: Int
    $booking_status: Int
    $search: JSON
  ) {
    get_ondemand_jobs_pagination(
      limit: $limit
      page: $page
      search: $search
      booking_status: $booking_status
    ) {
      pageInfo {
        totalDocs
        page
      }
      data {
        _id
        ref: ondemand_ref
        get_category_names(root_parent: true) {
          category_name
        }
        get_category_location(root_parent: true) {
          ondemand_min_price(root_currency: true)
        }
        get_user {
          first_name
          last_name
        }
        get_address_detail {
          address
        }
      }
    }
  }
`;
export const RESTART_ONDEMAND_EXPIRED = gql`
  mutation RESTARTONDEMANDEXPIRED($_id: ID, $booking_status: Int) {
    restart_ondemand_expired(_id: $_id, booking_status: $booking_status) {
      _id
      booking_status
      msg
      status
    }
  }
`;
export const GET_ONDEMAND_BIDING_PAGINATION = gql`
  query GETONDEMAND_BIDING_PAGINATION(
    $limit: Int
    $page: Int
    $data: JSON
    $ondemand_id: ID
    $location_code: String
    $sort: String
  ) {
    get_on_demand_biding_pagination(
      limit: $limit
      page: $page
      sort: $sort
      data: $data
      ondemand_id: $ondemand_id
      location_code: $location_code
    ) {
      pageInfo {
        totalDocs
        page
      }
      data {
        _id
        created_at
        ref: biding_ref
        description
        add_to_shortlist
        response_time
        budget(root_currency: true)
        response_time_formate
        provider_rating_by_category(root: true) {
          rating
        }
        get_user(root_parent: true) {
          img_url
          first_name
          online
          user_type
          last_name
          _id
          get_company_root_detail(root: true) {
            _id
            company_name
            company_website
            company_category
          }
        }
      }
    }
  }
`;
export const GET_ONDEMANDS = gql`
  query GETONDEMANDS($_id: ID) {
    get_ondemand_jobs(_id: $_id) {
      _id
      booking_status
      created_at
      close_date
      cancelled_by
      ref: ondemand_ref
      get_category_names(root_parent: true) {
        category_name
      }
      get_provider_user {
        img_url
        first_name
        email
        phone_no
        user_type
        last_name
        _id
        get_company_root_detail(root: true) {
          company_name
          company_website
          company_category
          get_company_images {
            small_image
          }
        }
      }
      get_user {
        img_url
        first_name
        user_type
        last_name
        email
        phone_no
        _id
        get_company_root_detail(root: true) {
          company_name
          company_website
          company_category
          get_company_images {
            small_image
          }
        }
      }
      get_address_detail {
        address
      }
    }
  }
`;
export const GET_ONDEMANDS_COMMON = gql`
  query GETONDEMANDSCOMMON($_id: ID) {
    get_ondemand_jobs(_id: $_id) {
      _id
      booking_date
      booking_status
      ref: ondemand_ref
      get_category_names(root_parent: true) {
        category_name
        paths
      }
      get_category_location(root_parent: true) {
        ondemand_min_price(root_currency: true)
      }
      biding_count
      created_at
      description
      get_ondemand_jobs_category_question(root_parent: true) {
        question
        option_type
        answer
        get_ondemand_files(ondemand_id: $_id, root: true) {
          small_image
        }
        multiple_option {
          label
          value
          is_select
        }
      }
    }
  }
`;

export const GET_ONDEMANDS_BILLS = gql`
  query GETONDEMANDSBILLS($_id: ID) {
    get_ondemand_jobs(_id: $_id) {
      _id
      booking_status
      base_price(root_currency: true)
      final_payment(root_currency: true)
      admin_fee(root_currency: true)
      service_fee
      total(root_currency: true)
    }
  }
`;

export const GET_ONDEMANDS_MILES = gql`
  query GETONDEMANDSMILES($_id: ID) {
    get_ondemand_jobs(_id: $_id) {
      _id
      booking_status
      start_date
      completed_date
      end_date
      start_url_image: get_ondemand_files(
        ondemand_id: $_id
        category: "start_images"
      ) {
        small_image
      }
      end_url_image: get_ondemand_files(
        ondemand_id: $_id
        category: "end_images"
      ) {
        small_image
      }
    }
  }
`;

export const GET_ONDEMANDS_INVOICE = gql`
  query GETONDEMANDSINVOICE($_id: ID) {
    get_ondemand_jobs(_id: $_id) {
      _id
      booking_date
      booking_status
      mpeas_payment_callback
      payment_type
      ctob_shotcode
      ctob_billRef
      ref: ondemand_ref
      base_price(root_currency: true)
      total(root_currency: true)
      final_payment(root_currency: true)
      service_fee
      admin_fee(root_currency: true)
      get_category_names(root_parent: true) {
        category_name
      }
      get_category_location(root_parent: true) {
        ondemand_min_price(root_currency: true)
      }
      created_at
      get_user {
        email
        first_name
        last_name
        role
        phone_number
      }
      get_provider_user {
        email
        first_name
        last_name
        role
        phone_number
      }
    }
  }
`;

export const Get_Ondemand_ExtraFareQuery = gql`
  query GetOndemandExtraFareQuery($ondemand_id: ID) {
    get_ondemand_extra_fee(ondemand_id: $ondemand_id) {
      _id
      extra_fare(root_currency: true)
      extra_fare_reason
    }
  }
`;

export const GET_ALL_ONDEMAND_DATA = gql`
    query GETONDEMANDPAGINATION(
      $limit: Int
      $page: Int
      $booking_status: Int
      $search: JSON
      $_id: ID
    ) {
      get_ondemand_jobs_pagination(
        limit: $limit
        page: $page
        search: $search
        booking_status: $booking_status
        _id:$_id
      ) {
        pageInfo {
          totalDocs
          page
        }
        data {
          _id
          ref: ondemand_ref
          user_id
          booking_date
          booking_status
          invoice_status
          get_category_names(root_parent: true) {
            category_name
          }
          created_at
          close_date
          cancelled_by
          get_ondemand_jobs_category_question(root_parent: true) {
            question
            option_type
            answer
            get_ondemand_files(ondemand_id: $_id, root: true) {
              small_image
            }
            multiple_option {
              label
              value
              is_select
            }
          }
          get_user
          {
              _id
              name
              first_name
              last_name
              email
              phone_number
              img_url
          }
          received_estimates{
            _id
            provider_id
            get_provider_user(root_parent:true)
            {
              _id
              img_url
              first_name
              last_name
              online
              user_type
              provider_rating_by_category
              {
                rating
              }
            }
            response_time
            find_kilometer
            {
              kilometre
            }
            description
            budget
          }
          shortlisted_estimates{
            _id
            provider_id
            get_provider_user(root_parent:true)
            {
              _id
              first_name
              last_name
              img_url
              provider_rating_by_category
              {
                rating
              }
            }
            response_time
            find_kilometer
            {
              kilometre
            }
            description
            budget
          }
          approved_estimate{
            _id
            provider_id
            description
            _id
            provider_id
            get_provider_user(root_parent:true)
            {
              _id
              first_name
              last_name
              email
              phone_number
              img_url
              provider_rating_by_category
              {
                rating
              }
            }
            response_time
            find_kilometer
            {
              kilometre
            }
            description
            budget
            get_milestones{
              _id
              get_milestone_all_images
              {
                small_image
              }
              description
              title
              budget
            }
            get_invoices{
              _id
              invoice_no
              invoice_date_format
              total
              service_fee
              extra_fare_reason
              extra_fare
              tax
              status
            }
          }
          get_address_detail {
            address
          }
          user_review
          {
            rating
            comments
          }
          provider_review
          {
            rating
            comments
          }
        }
      }
    }
`;