import React, { useEffect, useState } from "react";
import { ADD_ADDRESS, GET_ADDRESS } from "../../../graphql/User/address";
import { client } from "../../../apollo";
import { MdAdd } from "react-icons/md";
import { MdLocationOn, MdDeleteOutline } from "react-icons/md";
import { UPDATE_JOB } from "../../../graphql/User/job";
import gql from "graphql-tag";
import Addaddress from "./Addaddress";
import { IoMdArrowRoundBack } from "react-icons/io";

const DELETE_ADDRESS = gql`
  mutation delete_address($option: Int, $_id: ID) {
    modified_address(option: $option, _id: $_id) {
      msg
      status
    }
  }
`;

const Location = () => {
  const [newaddress, setnewaddress] = useState(false);
  const [user_address, setuser_address] = useState([]);
  const [selected_address, setselected_address] = useState(null);
  const [latitude, setlatitude] = useState(null);
  const [longitude, setlongitude] = useState(null);
  const [inputs, setInputs] = useState({
    address: "",
    flat_no: "",
    landmark: "",
    title: "",
    option: 1,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const addnewaddress = () => {
    setnewaddress(!newaddress);
  };
  useEffect(() => {
    getAlladdress();
  }, []);
  const getAlladdress = async () => {
    var providerdata = localStorage.getItem("user");
    if (JSON.parse(providerdata)._id !== null) {
      await client
        .query({
          query: GET_ADDRESS,
          variables: {
            user_id: JSON.parse(providerdata)._id,
          },
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          setuser_address(result.data.user_address);
        });
    }
  };
  const select_location = async (e) => {
    setselected_address(e.target.id);
  };
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };
  const [center, setCenter] = useState({
    lat: "11.0141979214483",
    lng: "76.96496996003773",
  });
  const handleApiLoaded = (e) => {
    setCenter({
      lat: e.lat,
      lng: e.lng,
    });
    setlatitude(e.lat);
    setlongitude(e.lng);
  };

  const saveBtn = async () => {
    var providerdata = localStorage.getItem("user");
    if (latitude === null || longitude === null) {
      alert("Please Select Location..");
    } else {
      inputs["lat"] = latitude.toString();
      inputs["lng"] = longitude.toString();
      inputs["user_id"] = JSON.parse(providerdata)._id;
      await client
        .query({
          query: ADD_ADDRESS,
          variables: inputs,
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          if (result.data.modified_address.msg === "success") {
            setnewaddress(false);
            getAlladdress();
          }
        });
    }
  };
  const Nextbtn = async () => {
    if (selected_address !== null) {
      sessionStorage.setItem("selected_address", selected_address);
      window.location.assign("/category");

    } else {
      alert("Please Select Address...");
    }
  };
  const deltebtn = async (e) => {
    await client
      .query({
        query: DELETE_ADDRESS,
        variables: {
          option: 3,
          _id: e.target.id,
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        if (result.data.modified_address.status === "success") {
          getAlladdress();
          setselected_address(null);
        }
      });
  };
  const cancelbtn = () => {
    window.location.replace("/category");
  };
  return (
    <>
      {newaddress === false ? (
        <div className="backbtndiv">
          <div className="sidebardivnew">
            <div className="select_catdiv">
              <div className="btn_grp_heading">
                <h2 className="newheadingclass">My Saved Address</h2>
                <h2
                  className="bold quicksand_font_bold addnew_add"
                  onClick={addnewaddress}
                >
                  <MdAdd onClick={addnewaddress} /> Add A New Address
                </h2>
              </div>

              <div className="address_list">
                {" "}
                {user_address.length !== 0
                  ? user_address.map((data, index) => (
                      <div
                        className="ant-card bg-transparent h-100 ant-card-hoverable addressdiv"
                        key={index}
                        id={data._id}
                        onClick={select_location}
                      >
                        {selected_address === data._id ? (
                          <input
                            type="radio"
                            id={data._id}
                            name="fav_language"
                            value={data._id}
                            checked
                          />
                        ) : (
                          <input
                            type="radio"
                            id={data._id}
                            name="fav_language"
                            value={data._id}
                          />
                        )}

                        <label
                          className="cat_label"
                          id={data._id}
                          htmlFor={data._id}
                        >
                          <h4
                            className="bold quicksand_font_bold newheadingclass"
                            id={data._id}
                          >
                            {data.title}
                          </h4>
                          <p className="m-0" id={data._id}>
                            {data.flat_no}
                            {data.address}
                            {data.landmark}
                          </p>
                        </label>
                        <button
                          type="button"
                          className="ant-btn ant-btn-link primary_color d-flex ant-btn-sm flex-column justify-content-center align-items-center"
                          id={data._id}
                          onClick={deltebtn}
                        >
                          <i
                            aria-label="icon: delete"
                            className="anticon anticon-delete m-0"
                          >
                            <svg
                              viewBox="64 64 896 896"
                              focusable="false"
                              className=""
                              data-icon="delete"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                            </svg>
                          </i>
                          <span className="m-0"> Delete</span>
                        </button>
                      </div>
                    ))
                  : null}
              </div>
              <div className="btn_grp">
                <button className="cancel_btn" onClick={cancelbtn}>
                  CANCEL
                </button>
                <button className="next_btn" onClick={Nextbtn}>
                  NEXT
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Addaddress
          addnewaddress={addnewaddress}
          getAlladdress={getAlladdress}
        />
        // <div className="sidebardivnew">
        //   <div className="select_catdiv">
        //     <div className="btn_grp">
        //       <h4 className="bold quicksand_font_bold f_25">Add Address</h4>
        //     </div>
        //     <div className="address_list">
        //       <div className="g_map">
        //         <GoogleMapReact
        //           bootstrapURLKeys={{ key: "" }}
        //           defaultCenter={defaultProps.center}
        //           defaultZoom={defaultProps.zoom}
        //           onClick={(e) => {
        //             handleApiLoaded(e);
        //           }}
        //         >
        //           <Marker lat={center.lat} lng={center.lng} icon="My Marker" />
        //         </GoogleMapReact>
        //       </div>
        //       <div className="mt-3">
        //         <h6 className="input_lable">Title</h6>
        //         <input
        //           type="text"
        //           className="form-control"
        //           placeholder="Title"
        //           name="title"
        //           defaultValue={inputs.title || ""}
        //           onChange={handleChange}
        //         />
        //       </div>
        //       <div className="mt-3">
        //         <h6 className="input_lable">Flat No</h6>
        //         <input
        //           type="text"
        //           className="form-control"
        //           placeholder="Flat No"
        //           name="flat_no"
        //           defaultValue={inputs.flat_no || ""}
        //           onChange={handleChange}
        //         />
        //       </div>
        //       <div className="mt-3">
        //         <h6 className="input_lable">Address</h6>
        //         <input
        //           type="text"
        //           className="form-control"
        //           placeholder="Address"
        //           name="address"
        //           defaultValue={inputs.address || ""}
        //           onChange={handleChange}
        //         />
        //       </div>
        //       <div className="mt-3">
        //         <h6 className="input_lable">Landmark</h6>
        //         <input
        //           type="text"
        //           className="form-control"
        //           placeholder="Landmark"
        //           name="landmark"
        //           defaultValue={inputs.landmark || ""}
        //           onChange={handleChange}
        //         />
        //       </div>
        //     </div>
        //     <div className="btn_grp">
        //       <button className="cancel_btn" onClick={addnewaddress}>
        //         CANCEL
        //       </button>
        //       <button className="next_btn" onClick={saveBtn}>
        //         NEXT
        //       </button>
        //     </div>
        //   </div>
        // </div>
      )}
    </>
  );
};
const Marker = (props) => {
  return <MdLocationOn className="marker" />;
};

export default Location;
