import React, { useEffect, useState } from "react";
import { GrAddCircle } from "react-icons/gr";
import model_img from "../../../image/cloud-modal.png";
import { GET_QUESTION } from "../../../graphql/User/job";
import { client } from "../../../apollo";
import { useParams } from "react-router-dom";
import { UPDATE_JOB } from "../../../graphql/User/job";
import { Modal } from "antd";
import { IoMdArrowRoundBack } from "react-icons/io";

const ProjectReview = ({ Editquestion, Oldquestion }) => {
  const [reviewdata, setreviewdata] = useState(null);
  const [addressdata, setaddressdata] = useState(null);
  const [categorydata, setcategorydata] = useState(null);

  const [jobid, setjobid] = useState(null);
  const [questiondata, setquestiondata] = useState([]);
  const [model, setmodel] = useState(false);
  let { categoryid } = useParams();
  const openmodel = async () => {
    // setmodel(true);
    var providerdata = localStorage.getItem("user");
    if (JSON.parse(providerdata)._id !== null) {
      await client
        .query({
          query: UPDATE_JOB,
          variables: {
            ondemand_data: [
              {
                category_id: reviewdata.get_ondemand_jobs[0].category_id,
                address_id: reviewdata.get_ondemand_jobs[0].address_id,
                user_id: JSON.parse(providerdata)._id,
              },
            ],
            _id: categoryid,
            local_location_code: JSON.parse(providerdata).location_code,
            location_code: null,
            booking_status: 9,
            booking_type: 1,
          },
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          setjobid(result.data.Update_OnDemand._id);
          if (
            result.data.Update_OnDemand.msg === "ondemand job update success"
          ) {
            setmodel(true);
            setTimeout(() => {
              const ele = document.getElementsByClassName("ant-modal-footer");
              ele[0].style.display = "none";
            }, 100);
      
          }
        });
    }
  };
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var providerdata = localStorage.getItem("user");
    if (JSON.parse(providerdata)._id !== null) {
      await client
        .query({
          query: GET_QUESTION,
          variables: {
            _id: categoryid,
            user_id: JSON.parse(providerdata)._id,
          },
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          setreviewdata(result.data);
          setaddressdata(result.data.get_ondemand_jobs[0].get_address_detail);
          setcategorydata(result.data.get_ondemand_jobs[0].get_category_names);
          setquestiondata(
            result.data.get_ondemand_jobs[0].get_ondemand_jobs_category_question
          );
        });
    }
  };
  const editbtn = (e) => {
    Editquestion(e.target.id);
  };
  const continubtn = () => {
    window.location.replace(`/myproject`);
  };
  return (
    <div className="backbtndiv">
      <h6
        onClick={() => {
          Oldquestion();
        }}
      >
        <IoMdArrowRoundBack className="backbtn" />
        <b className="backname">Back</b>
      </h6>
      <div className="sidebardivnewquestionreview">
        <div className="select_catdiv">
          <h2 className="quicksand_font_bold f_30 text-center">
            Review and Publish Your Project
          </h2>
          <div className="reviewgroup_grp">
            <div className="mt-3">
              <h2 className="newheadingclass">Project Location</h2>
              {addressdata !== null ? (
                <p className="f_21">{addressdata.address}</p>
              ) : null}
            </div>
            <h6 className="bold quicksand_font_bold f_21 skipbtn mt-3">Edit</h6>
          </div>

          <div className="reviewgroup_grp">
            <div className="mt-3">
              <h2 className="newheadingclass">What do you need ?</h2>
              {categorydata !== null ? (
                <p className="f_21">{categorydata.paths}</p>
              ) : null}
            </div>
            <h6 className="bold quicksand_font_bold f_21 skipbtn mt-3">Edit</h6>
          </div>
          {questiondata.length !== 0
            ? questiondata.map((data, index) =>
                data.option_type === "radio" ||
                data.option_type === "checkbox" ? (
                  <div className="reviewgroup_grp" key={index}>
                    <div className="mt-3">
                      <h2 className="newheadingclass">{data.question}</h2>
                      {data.multiple_option.map((datanew, indexnew) =>
                        datanew.is_select === true ? (
                          <p className="f_21" key={indexnew}>
                            {datanew.label} {datanew.value}
                          </p>
                        ) : null
                      )}
                    </div>
                    <h6
                      className="bold quicksand_font_bold f_21 skipbtn mt-3"
                      id={index}
                      onClick={editbtn}
                    >
                      Edit
                    </h6>
                  </div>
                ) : data.option_type === "textbox" ? (
                  <div className="reviewgroup_grp" key={index}>
                    <div className="mt-3">
                      <h2 className="newheadingclass">{data.question}</h2>
                      <p className="f_21">{data.answer}</p>
                    </div>
                    <h6
                      className="bold quicksand_font_bold f_21 skipbtn mt-3"
                      id={index}
                      onClick={editbtn}
                    >
                      Edit
                    </h6>
                  </div>
                ) : data.option_type === "file" ? (
                  <div className="reviewgroup_grp" key={index}>
                    <div className="mt-3">
                      <h2 className="newheadingclass">{data.question}</h2>
                      <div className="doc_list mt-3">
                        {data.get_ondemand_files.length !== 0 ? (
                          data.get_ondemand_files.map((dataimg, index) => (
                            <div className="dropzone" key={index}>
                              <img
                                alt=""
                                className="dropzone"
                                src={dataimg.small_image}
                              />
                            </div>
                          ))
                        ) : (
                          <div>
                            <p className="f_21">No file Found..</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <h6
                      className="bold quicksand_font_bold f_21 skipbtn mt-3"
                      id={index}
                      onClick={editbtn}
                    >
                      Edit
                    </h6>
                  </div>
                ) : null
              )
            : null}
          <div className="btn_grp">
            <button className="cancel_btn">CANCEL</button>
            <button id="myBtn" onClick={openmodel} className="postbtn">
              POST PROJECT
            </button>
          </div>
          <Modal
            title="Project Submitted Successfully"
            className="new_modal"
            // className="test_modal"
            centered
            visible={model}
            destroyOnClose={true}
            // onCancel={() => setmodel(false)}
          >
            <div className="w-100 p-4">
              <img className="model_img" src={model_img} alt="model_img" />
              <h6 className="bold quicksand_font_bold f_26 text-center">
                {/* Once Your Project Approved, <br />
                You Will be able to View Gigzzy Pros. */}
                Your Project is ready for Estimation
              </h6>
              <button className="contibtn" onClick={continubtn}>
                CONTINUE
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ProjectReview;
