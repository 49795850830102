import React, { Suspense, useEffect, useState } from "react";
import Skeleton from "antd/lib/skeleton";
import Tabs from "antd/lib/tabs";
import OndemandProjects from "./OndemandProjects";
const { TabPane } = Tabs;

const Myproject = () => {
  const [firsttabid, setfirsttabid] = useState("1");
  const [secondtabid, setsecondtabid] = useState("1");
  const [thirdtabid, setthirdtabid] = useState("1");
  const [fourthtabid, setfourthtabid] = useState("1");
  const [fifthtabid, setfifthtabid] = useState("1");
  const tabChange = (key) => {
    switch (key) {
      case "1":
        setfirsttabid((Number(firsttabid) * Date.now()).toString());
        break;
      case "2":
        setsecondtabid((Number(secondtabid) * Date.now()).toString());
        break;
      case "3":
        setthirdtabid((Number(thirdtabid) * Date.now()).toString());
        break;
      case "4":
        setfourthtabid((Number(fourthtabid) * Date.now()).toString());
        break;
      case "5":
        setfifthtabid((Number(fifthtabid) * Date.now()).toString());
        break;
      default:
        break;
    }
  };
  return (
    <div className="container">
      <h4 className="bold mb-3 mt-3">My Projects</h4>
      <Suspense fallback={<Skeleton />}>
        <Tabs defaultActiveKey="1" className="border" onChange={tabChange}>
          <TabPane tab="Unawarded" key="1" className="p-3">
            <Suspense fallback={<Skeleton />}>
              <OndemandProjects
                status="unawarded"
                id={firsttabid}
                booking_status="21"
              />
            </Suspense>
          </TabPane>
          <TabPane tab="Awarded" key="2" className="p-3">
            <Suspense fallback={<Skeleton />}>
              <OndemandProjects
                status="awarded"
                id={secondtabid}
                booking_status="20"
              />
            </Suspense>
          </TabPane>
          <TabPane tab="Archived" key="3" className="p-3">
            <Suspense fallback={<Skeleton />}>
              <OndemandProjects
                status="archived"
                id={thirdtabid}
                booking_status="100"
              />
            </Suspense>
          </TabPane>
          <TabPane tab="All Projects" key="4" className="p-3">
            <Suspense fallback={<Skeleton />}>
              <OndemandProjects
                status="allProjects"
                id={fourthtabid}
                booking_status={null}
              />
            </Suspense>
          </TabPane>
        </Tabs>
      </Suspense>
    </div>
  );
};

export default Myproject;
