import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SINGLE_JOB, SINGLE_JOB_MORE } from "../../../graphql/User/job";
import { client } from "../../../apollo";
import { GoLocation } from "react-icons/go";
import { Modal } from "antd";
import GoogleMapReact from "google-map-react";
import OnDemandDetail from '../../Admin/OnDemand/OnDemandDetail'


const Jobaction = () => {
  let { jobid } = useParams();
  const [jobdetails, setjobdetails] = useState([]);
  const [joblocation, setjoblocation] = useState([]);
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    await client
      .query({
        query: SINGLE_JOB,
        variables: {
          _id: jobid,
          qid: "",
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        setjobdetails(result.data.get_ondemand_jobs);
      });
    await client
      .query({
        query: SINGLE_JOB_MORE,
        variables: {
          _id: jobid,
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        setjoblocation(result.data.get_ondemand_jobs);
      });
  };
  const [model, setmodel] = useState(false);
  const [latitude, setlatitude] = useState(null);
  const [longitude, setlongitude] = useState(null);
  const [draggable] = useState(true);
  const [center, setCenter] = useState([9.9619289, 78.1288218]);
  const [zoom] = useState(10);
  const [address, setaddress] = useState(null);
  const viewlocation = async () => {
    if (joblocation.length !== 0) {
      setmodel(true);
      setlatitude(joblocation[0].get_address_detail.lat);
      setlongitude(joblocation[0].get_address_detail.lng);
      setaddress(joblocation[0].get_address_detail.address);
      setCenter([
        Number(joblocation[0].get_address_detail.lat),
        Number(joblocation[0].get_address_detail.lng),
      ]);
    }
  };
  const Viewmilestone = async () => {
    window.location.replace(`/milestones/${jobid}`);
  };
  return (
    <>
      {/* <div className="container">
      {joblocation.length !== 0 ? (
        <div className="location_div mt-3">
          <div>
            <h3>
              <b>{joblocation[0].get_category_names.category_name}</b>
            </h3>
            <h6>
              <b>Booking Ref :</b> {joblocation[0].ondemand_ref}
            </h6>
            <h6>
              <b>Location :</b> {joblocation[0].get_address_detail.address}
            </h6>
          </div>
          <div>
            <span className="locationicon" onClick={viewlocation}>
              <GoLocation />
            </span>
            <h6 className="mt-2">
              <b>Status : </b>
              <span className="statuslabel">
                {joblocation[0].booking_status === 10
                  ? "Pending"
                  : joblocation[0].booking_status === 9
                  ? "Estimated"
                  : "Waiting for payment"}
              </span>
            </h6>
          </div>
        </div>
      ) : null}
      <div className="location_div mt-5">
        <h4>
          <b>Job Details</b>
        </h4>
      </div>
      {jobdetails.length !== 0
        ? jobdetails[0].get_ondemand_jobs_category_question.map((data, index) =>
            data.option_type === "radio" || data.option_type === "checkbox" ? (
              <div className="reviewgroup_grp" key={index}>
                <div className="mt-3">
                  <h4 className="bold quicksand_font_bold f_26">
                    {data.question}
                  </h4>
                  {data.multiple_option.map((datanew, indexnew) =>
                    datanew.is_select === true ? (
                      <p className="f_21" key={indexnew}>
                        {datanew.label}
                      </p>
                    ) : null
                  )}
                </div>
              </div>
            ) : data.option_type === "textbox" ? (
              <div className="reviewgroup_grp" key={index}>
                <div className="mt-3">
                  <h4 className="bold quicksand_font_bold f_26">
                    {data.question}
                  </h4>
                  <p className="f_21">{data.answer}</p>
                </div>
              </div>
            ) : data.option_type === "file" ? (
              <div className="reviewgroup_grp" key={index}>
                <div className="mt-3">
                  <h4 className="bold quicksand_font_bold f_26">
                    {data.question}
                  </h4>
                  <div className="doc_list mt-3">
                    {data.get_ondemand_files.length !== 0 ? (
                      data.get_ondemand_files.map((dataimg, index) => (
                        <div className="dropzone" key={index}>
                          <img
                            alt=""
                            className="dropzone"
                            src={dataimg.small_image}
                          />
                        </div>
                      ))
                    ) : (
                      <div>
                        <p className="f_21">No file Found..</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null
          )
        : null}

      <Modal
        title="On-Demand Location"
        className="new_modal"
        centered
        visible={model}
        // destroyOnClose={true}
        onCancel={() => setmodel(false)}
      >
        <div className="w-100 p-4">
          <div style={{ height: "30vh", width: "100%" }}>
            <GoogleMapReact draggable={draggable} center={center} zoom={zoom}>
              <div className="place" lat={latitude} lng={longitude}>
                <img
                  src={require("../../../image/pin_location.png")}
                  alt="your Place"
                />
              </div>
            </GoogleMapReact>
          </div>
          <label className="mt-2">
            <b>On-Demand Job Address</b>
          </label>
          <br />
          <p className="locationtage">{address}</p>
        </div>
      </Modal>
      </div> */}
      <div className="container py-3">
         <OnDemandDetail/>
      </div>
    </>
   
  );
};

export default Jobaction;
