import React, { useState } from "react";
import { Icon, Input, Button } from "antd";
import GoogleMapReact from "google-map-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
import { Alert_msg } from "../../Comman/alert_msg";
import { client } from "../../../apollo";
import { ADD_ADDRESS } from "../../../graphql/User/address";

const Addaddress = ({ addnewaddress, getAlladdress }) => {
  const [draggable, setdraggable] = useState(true);
  const [zoom, setzoom] = useState(15);
  const [address, setaddress] = useState("");
  const [country_code, set_country_code] = useState("");
  const [lat, setlat] = useState(9.9619289);
  const [lng, setlng] = useState(78.1288218);
  const [flat_no, setflat_no] = useState("");
  const [landmark, setlandmark] = useState("");
  const [title, settitle] = useState("Others");
  const [center, setcenter] = useState([9.9619289, 78.1288218]);

  const handleChange1 = (address) => {
    setaddress(address);
  };
  const handleSelect = (address) => {
    setaddress(address);
    geocodeByAddress(address)
      .then(async (results) => {
        if (results[0]) {
          results[0].address_components.map((data) => {
            if (data.types.includes("country")) {
              set_country_code(data.short_name);
            }
          });
          let latLng = await getLatLng(results[0]);
          setlat(latLng.lat);
          setlng(latLng.lng);
          setcenter([latLng.lat, latLng.lng]);
        }
      })
      .catch((error) => console.error("Error", error));
  };
  const onCircleInteraction = async (childKey, childProps, mouse) => {
    // function is just a stub to test callbacks
    setdraggable(false);
    setlat(mouse.lat);
    setlng(mouse.lng);
    Geocode.setApiKey("AIzaSyAztp7xq-60ajr0cI3qCKxx8uzkz9Ts-lk");
    // Get address from latidude & longitude.
    await Geocode.fromLatLng(mouse.lat, mouse.lng).then(
      (response) => {
        if (response.results[0].address_components) {
          response.results[0].address_components.map((data) => {
            if (data.types.includes("country")) {
              set_country_code(data.short_name);
            }
          });
        }
        const address = response.results[0].formatted_address;
        setaddress(address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onCircleInteraction3 = (childKey, childProps, mouse) => {
    setdraggable(true);
  };
  const _onChange = ({ center, zoom }) => {
    setcenter(center);
    setzoom(zoom);
  };
  const saveadd = async () => {
    var providerdata = localStorage.getItem("user");
    var data = {
      option: 1,
      user_id: JSON.parse(providerdata)._id,
      title: title,
      flat_no: flat_no,
      landmark: landmark,
      address: address,
      lat: String(lat),
      lng: String(lng),
      location_code: country_code,
    };
    if (
      data.lat === null ||
      data.lat === "" ||
      data.lng === "" ||
      data.lng === null ||
      data.address === "" ||
      data.address === null ||
      data.user_id === "" ||
      data.user_id === null ||
      data.title === "" ||
      data.title === null
    ) {
      Alert_msg({ msg: "Please add mandatory field", status: "failed" });
    } else {
      await client
        .query({
          query: ADD_ADDRESS,
          variables: data,
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          if (result.data.modified_address.msg === "success") {
            Alert_msg({ msg: "Address saved", status: "success" });
            setTimeout(() => {
              addnewaddress();
              getAlladdress();
            }, 2000);
          }
        });
    }
  };
  return (
    <>
      <div className="sidebardivnew p-5">
        <h4 className="bold quicksand_font_bold newheadingclass">Add Address</h4>
        <div className="my-2">
          <div className="d-flex">
            <PlacesAutocomplete
              value={address}
              onChange={handleChange1}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="position-relative w-100">
                  <input
                    {...getInputProps({
                      placeholder: "Location",
                      icon: "eye",
                      className:
                        "location-search-input jiffy_input place_input loc_img",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div className="suggest_load">Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                            display: "flex",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                            display: "flex",
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>

        <div style={{ height: "30vh", width: "100%" }}>
          <GoogleMapReact
            draggable={draggable}
            onChange={_onChange}
            center={center}
            zoom={zoom}
            onChildMouseDown={onCircleInteraction}
            onChildMouseUp={onCircleInteraction3}
            onChildMouseMove={onCircleInteraction}
            bootstrapURLKeys={{ key: "AIzaSyAztp7xq-60ajr0cI3qCKxx8uzkz9Ts-lk" }}
          >
            <div className="place" lat={lat} lng={lng}>
              <img
                src={require("../../../image/pin_location.png")}
                alt="your Place"
              />
            </div>
          </GoogleMapReact>
        </div>
        <div className="my-2">
          {/* <div className="d-flex">
            <PlacesAutocomplete
              value={address}
              onChange={handleChange1}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="position-relative w-100">
                  <input
                    {...getInputProps({
                      placeholder: "Location",
                      icon: "eye",
                      className:
                        "location-search-input jiffy_input place_input loc_img",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div className="suggest_load">Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                            display: "flex",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                            display: "flex",
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div> */}
          <div className="my-2">
            <Input
              size="large"
              value={flat_no}
              placeholder="Enter Flat No"
              onChange={(event) => {
                setflat_no(event.target.value);
              }}
            />
          </div>
          <div className="my-2">
            <Input
              size="large"
              value={landmark}
              placeholder="Enter LandMark"
              onChange={(event) => {
                setlandmark(event.target.value);
              }}
            />
          </div>
          <div
            className={
              title !== "Home" && title !== "Work" ? "my-2 d-flex" : "d-none"
            }
          >
            <Input
              size="large"
              value={title}
              placeholder="Enter title"
              onChange={(event) => {
                settitle(event.target.value);
              }}
            />
          </div>
          <div className="my-2 d-flex justify-content-around">
            <Button
              className={
                title === "Home" ? "d-flex primary_color" : "d-flex no_color"
              }
              type="link"
              onClick={() => {
                settitle("Home");
              }}
            >
              <Icon type="home" /> Home
            </Button>
            <Button
              className={
                title === "Work" ? "d-flex primary_color" : "d-flex no_color"
              }
              type="link"
              onClick={() => {
                settitle("Work");
              }}
            >
              <Icon type="shop" />
              Work
            </Button>
            <Button
              className={
                title !== "Home" && title !== "Work"
                  ? "d-flex primary_color"
                  : "d-flex no_color"
              }
              type="link"
              onClick={() => {
                settitle("Others");
              }}
            >
              <Icon type="security-scan" />
              Others
            </Button>
          </div>
          <div className="btn_grp">
            <button
              className="cancel_btn"
              onClick={() => {
                addnewaddress();
              }}
            >
              CANCEL
            </button>
            <button className="next_btn" onClick={saveadd}>
              SAVE
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addaddress;
