import React, { useState } from "react";
import { Timeline, Tag, Modal, Form, Skeleton, Badge, Spin } from "antd";
import includes from "lodash/includes";
import { useParams } from "react-router-dom";

const Milestones = () => {
  let { jobid } = useParams();
  const [model, setmodel] = useState(false);
  const Create_milestone = async () => {
    window.location.replace(`/createmilestone/${jobid}`);
  };
  const viewbtn = () => {
    setmodel(true);
  };
  return (
    <div className="container">
      <div className="mt-5 mb-5 d-flex justify-content-between">
        <h4>
          <b>Project Milestones</b>
        </h4>
        <button className="createbtn" onClick={Create_milestone}>
          Create Milestone
        </button>
      </div>
      <Timeline>
        <Timeline.Item
          //   color={data?.booking_status === 14 ? "green" : "gray"}
          color={"green"}
          className="pb-4"
        >
          <div className="normal_font_size bold">Milestone 1: Title</div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {!includes(
                ["Started", "Complete", "Initialize Project"],
                "title"
              ) && (
                <div className="normal_font_size primary_color py-1">2000</div>
              )}
            </div>
            <div className="d-flex">
              <div className="bold">
                <Badge status="success" /> 24 Aug 2022
              </div>
            </div>
            <div className="d-flex">
              <div className="text-danger px-3">booking_status</div>
            </div>
            <div className="">
              <Tag
                color="green"
                className="cursor_point normal_font_size p-2"
                onClick={viewbtn}
              >
                View Detail
              </Tag>
            </div>
          </div>
        </Timeline.Item>
        <Timeline.Item
          //   color={data?.booking_status === 14 ? "green" : "gray"}
          color={"green"}
          className="pb-4"
        >
          <div className="normal_font_size bold">Milestone 1: Title</div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {!includes(
                ["Started", "Complete", "Initialize Project"],
                "title"
              ) && (
                <div className="normal_font_size primary_color py-1">2000</div>
              )}
            </div>
            <div className="d-flex">
              <div className="bold">
                <Badge status="success" /> 24 Aug 2022
              </div>
            </div>
            <div className="d-flex">
              <div className="text-danger px-3">booking_status</div>
            </div>
            <div className="">
              <Tag
                color="green"
                className="cursor_point normal_font_size p-2"
                onClick={viewbtn}
              >
                View Detail
              </Tag>
            </div>
          </div>
        </Timeline.Item>
        {/* <Timeline.Item
          color={"gray"}
          className="pb-4"
        >
          <div className="normal_font_size bold">Milestone 1: Title</div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {!includes(
                ["Started", "Complete", "Initialize Project"],
                "title"
              ) && (
                <div className="normal_font_size primary_color py-1">2000</div>
              )}
              <div className="d-flex">
                <div className="bold">
                  <Badge status="success" /> Date
                </div>
                <div className="text-danger px-3">booking_status</div>
              </div>
            </div>
            <div className="">
              <Tag
                color="green"
                className="cursor_point normal_font_size p-2"
              >
                View Detail
              </Tag>
            </div>
          </div>
        </Timeline.Item> */}
      </Timeline>

      <Modal
        className="new_modal"
        centered
        visible={model}
        // destroyOnClose={true}
        onCancel={() => setmodel(false)}
      >
        <div className="p-5">
          <h4 className="text-center">Milestone 1</h4>
          <h6>
            <b>Title : </b> Title
          </h6>
          <h6>
            <b>Description</b>
          </h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
            sed ante risus. Duis posuere arcu eget auctor euismod. Vestibulum
            sem nunc, accumsan sit amet odio nec, sagittis fringilla augue.
          </p>
          <h6>
            <b>Image</b>
          </h6>
        </div>
      </Modal>
    </div>
  );
};

export default Milestones;
