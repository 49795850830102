import gql from "graphql-tag";

export const UPDATE_JOB = gql`
  mutation UpdateOnDemand(
    $ondemand_data: JSON
    $_id: ID
    $local_location_code: String
    $location_code: String
    $booking_status: Int
    $booking_hours: String
    $booking_date: String
    $booking_time: String
    $booking_type: Int
  ) {
    Update_OnDemand(
      ondemand_data: $ondemand_data
      _id: $_id
      local_location_code: $local_location_code
      location_code: $location_code
      booking_status: $booking_status
      booking_hours: $booking_hours
      booking_date: $booking_date
      booking_time: $booking_time
      booking_type: $booking_type
    ) {
      msg
      status
      _id
      category_id
    }
  }
`;

export const GET_QUESTION = gql`
  query GetOndemandJobs($qid: ID, $_id: ID, $user_id: ID) {
    get_ondemand_jobs(_id: $_id, user_id: $user_id) {
      get_ondemand_jobs_category_question(qid: $qid, _id: $_id) {
        _id
        question
        is_skip
        answer
        multiple_option {
          _id
          is_select
          value
          label
        }
        option_type
        single_option
        get_ondemand_files(ondemand_id: $_id, root: true) {
          _id
          small_image
        }
      }
      address_id
      category_id
      _id
      get_address_detail(root: true) {
        lat
        address
        location_code
        lng
      }
      get_category_names(root_parent: true) {
        category_name
        paths
        _id
        msg
        status
      }
    }
  }
`;

export const ANSWER_QUESTION = gql`
  mutation UpdateOnDemandAnswer(
    $_id: ID
    $qid: ID
    $type: String
    $multiple_option: JSON
    $answer: String
    $file: [Upload]
  ) {
    Update_OnDemand_Answer(
      _id: $_id
      qid: $qid
      type: $type
      multiple_option: $multiple_option
      answer: $answer
      file: $file
    ) {
      msg
      status
      get_ondemand_jobs_category_question {
        _id
        question
        is_skip
        option_type
        multiple_option {
          label
          value
          is_select
        }
        get_ondemand_files(ondemand_id: $_id, root: true) {
          _id
          small_image
        }
      }
    }
  }
`;

export const ANSWER_QUESTION_checkbox = gql`
  mutation UpdateOnDemandAnswer(
    $_id: ID
    $qid: ID
    $type: String
    $multiple_option: JSON
    $answer: String
    $file: [Upload]
  ) {
    Update_OnDemand_Answer(
      _id: $_id
      qid: $qid
      type: $type
      multiple_option: $multiple_option
      answer: $answer
      file: $file
    ) {
      msg
      status
      get_ondemand_jobs_category_question {
        _id
        question
        is_skip
        option_type
        multiple_option {
          label
          value
          is_select
        }
        get_ondemand_files(ondemand_id: $_id, root: true) {
          _id
          small_image
        }
      }
    }
  }
`;

export const ANSWER_QUESTION_TEXTBOX = gql`
  mutation UpdateOnDemandAnswer(
    $_id: ID
    $qid: ID
    $type: String
    $multiple_option: JSON
    $answer: String
    $file: [Upload]
  ) {
    Update_OnDemand_Answer(
      _id: $_id
      qid: $qid
      type: $type
      multiple_option: $multiple_option
      answer: $answer
      file: $file
    ) {
      msg
      status
      get_ondemand_jobs_category_question {
        _id
        question
        is_skip
        option_type
        multiple_option {
          label
          value
          is_select
        }
        get_ondemand_files(ondemand_id: $_id, root: true) {
          _id
          small_image
        }
      }
    }
  }
`;

export const ANSWER_QUESTION_FILE = gql`
  mutation OnDemandJobFileUpload(
    $file: [Upload]
    $ondemand_id: ID
    $q_id: ID
    $image_tag: String
  ) {
    OnDemandJobFileUpload(
      file: $file
      ondemand_id: $ondemand_id
      q_id: $q_id
      image_tag: $image_tag
    ) {
      msg
      status
      get_ondemand_files(ondemand_id: $ondemand_id, root: true) {
        _id
        small_image
      }
    }
  }
`;

export const DELETE_FILE_IMAGE = gql`
  mutation DeleteOndemandImage($_id: ID, $ondemand_id: ID) {
    delete_ondemand_image(_id: $_id, ondemand_id: $ondemand_id) {
      msg
    }
  }
`;

export const VIEW_JOB = gql`
  query GetOndemandJobsPagination(
    $limit: Int
    $page: Int
    $role: Int
    $booking_status: Int
    $user_id: ID
    $lat: Float
    $lng: Float
    $location_code: String
  ) {
    get_ondemand_jobs_pagination(
      limit: $limit
      page: $page
      role: $role
      booking_status: $booking_status
      user_id: $user_id
    ) {
      pageInfo {
        totalDocs
      }
      data {
        _id
        booking_status
        milestone_status
        ondemand_ref
        base_price
        booking_date
        booking_type
        booking_time
        job_status
        user_image_url
        description
        lat
        lng
        biding_id
        created_at
        cancelled_by
        get_user {
          first_name
          last_name
          img_url
        }
        get_category_names(root_parent: true) {
          _id
          category_name
        }
        get_catgeory_images(root_parent: true) {
          small_image
        }
        find_kilometer(lat: $lat, lng: $lng, root: true) {
          kilometre
        }
        get_category_location(root_parent: true) {
          ondemand_min_price(code: $location_code, format: $location_code)
        }
        get_address_detail(root: true) {
          address
          lat
          lng
        }
        get_provider_user {
          phone_no
          email
          lat
          lng
          _id
          img_url
          first_name
          last_name
        }
        get_address_detail(root: true) {
          lat
          lng
          address
        }
      }
    }
  }
`;

export const UNAWARDED_JOB = gql`
  query GetOnDemandJobsPaginaton(
    $limit: Int
    $page: Int
    $role: Int
    $booking_status: Int
    $user_id: ID
    $location_code: String
  ) {
    get_ondemand_jobs_pagination(
      limit: $limit
      page: $page
      role: $role
      booking_status: $booking_status
      user_id: $user_id
    ) {
      pageInfo {
        totalDocs
        page
      }
      data {
        _id
        booking_date
        milestone_status
        base_price
        payment_option
        payment_type
        admin_fee
        biding_id
        biding_count
        ctob
        ctob_shotcode
        ctob_billRef
        provider_id
        booking_ref
        booking_alert
        booking_type
        user_msg_is_read
        user_msg_count
        provider_msg_is_read
        provider_msg_count
        booking_status
        ondemand_ref
        description
        get_category_names(root_parent: true) {
          category_name
          category_type
        }
        get_provider_user {
          _id
          first_name
          last_name
          image
          description
        }
        get_ondemand_all_files {
          small_image
        }
        get_user {
          _id
          first_name
          last_name
        }
        get_catgeory_images(root_parent: true) {
          small_image
        }
        get_address_detail {
          _id
          address
          lat
          lng
        }
        get_category_location(root_parent: true) {
          ondemand_min_price(code: $location_code)
        }
      }
    }
  }
`;

export const ONDEMAND_PROJECTS = gql`
  query GetOnDemandJobsPaginaton(
    $limit: Int
    $page: Int
    $role: Int
    $booking_status: Int
    $user_id: ID
    $location_code: String
  ) {
    get_ondemand_jobs_pagination(
      limit: $limit
      page: $page
      role: $role
      booking_status: $booking_status
      user_id: $user_id
    ) {
      pageInfo {
        totalDocs
        page
      }
      data {
        _id
        booking_date
        milestone_status
        base_price
        payment_option
        payment_type
        admin_fee
        biding_id
        biding_count
        ctob
        ctob_shotcode
        ctob_billRef
        provider_id
        booking_ref
        booking_alert
        booking_type
        user_msg_is_read
        user_msg_count
        provider_msg_is_read
        provider_msg_count
        booking_status
        ondemand_ref
        description
        get_category_names(root_parent: true) {
          category_name
          category_type
        }
        get_provider_user {
          _id
          first_name
          last_name
          image
          description
        }
        get_ondemand_all_files {
          small_image
        }
        get_user {
          _id
          first_name
          last_name
        }
        get_catgeory_images(root_parent: true) {
          small_image
        }
        get_address_detail {
          _id
          address
          lat
          lng
        }
        get_category_location(root_parent: true) {
          ondemand_min_price(code: $location_code)
        }
        before_archive
      }
    }
  }
`;

export const SINGLE_JOB = gql`
  query GetOndemandJobs($qid: ID, $_id: ID, $user_id: ID) {
    get_ondemand_jobs(_id: $_id, user_id: $user_id) {
      get_ondemand_jobs_category_question(qid: $qid, _id: $_id) {
        _id
        question
        is_skip
        answer
        multiple_option {
          _id
          is_select
          value
          label
        }
        option_type
        single_option
        get_ondemand_files(ondemand_id: $_id, root: true) {
          _id
          small_image
        }
      }
      address_id
      category_id
      _id
      get_address_detail(root: true) {
        lat
        address
        location_code
        lng
      }
      get_category_names(root_parent: true) {
        category_name
        paths
        _id
        msg
        status
      }
    }
  }
`;

export const SINGLE_JOB_MORE = gql`
query GetOndemandJobDetails($_id: ID, $location_code: String) {
  get_ondemand_jobs(_id: $_id) {
    _id
    msg
    status
    ondemand_ref
    cancelled_by
    biding_id
    booking_date
    payment_option(code: $location_code)
    final_payment(code: $location_code, format: $location_code)
    total(code: $location_code, format: $location_code)
    base_price(code: $location_code, format: $location_code)
    biding_id
    get_provider_user {
      _id
      first_name
      last_name
      img_url
      name
      image
    }
    admin_fee(code: $location_code)
    provider_id
    get_category_names(root_parent: true) {
      _id
      category_name
      category_type
      category_id
    }
    get_address_detail {
      _id
      address
      lat
      lng
    }
    get_user {
      _id
      name
      get_company_root_detail {
        company_name
        company_category
        company_website
      }
    }
    get_biding_detail(root: true) {
      _id
      add_to_shortlist
      biding_ref
      created_at
      user_id
      description
      experience
      provider_id
      contract_id
      timeline
      timeline_type
      cover_letter
      service_fee
      payment_option(code: $location_code, format: $location_code)
      provider_fee(code: $location_code, format: $location_code)
      pro_fee_temp: provider_fee
      pro_fee_with_currency: provider_fee(code: $location_code)
      budget(code: $location_code, format: $location_code)
      budget_without_currency: budget
      service_fee
      admin_fee(code: $location_code, format: $location_code)
      ctob
      ctob_billRef
      ctob_shotcode
      payment_type

      get_biding_all_files(root: true) {
        _id
        small_image
      }
      provider_rating_by_category(root: true) {
        rating
      }
      get_user(root_parent: true) {
        _id
        img_url
        first_name
        user_type
        last_name
        _id
        lat
        lng
        rating
        get_company_root_detail(root: true) {
          _id
          company_name
          company_website
          company_category
          about_company
          provider_id
          get_company_user_detail {
            first_name
            last_name
            description
          }
          get_company_images(root: true) {
            image_tag
            small_image
          }
        }
      }
    }
    ctob
    ctob_billRef
    ctob_shotcode
    category {
      category_name
    }
    payment_type
    booking_status
    description
    milestone_status
    payment_status

    job_start_time
    job_end_time
    get_ondemand_all_files(ondemand_id: $_id) {
      small_image
    }

    get_category_location(root_parent: true) {
      ondemand_min_price(code: $location_code, format: $location_code)
    }
    get_catgeory_images(root_parent: true) {
      small_image
    }

    start_url_image: get_ondemand_files(
      ondemand_id: $_id
      category: "start_images"
    ) {
      small_image
    }
    end_url_image: get_ondemand_files(
      ondemand_id: $_id
      category: "end_images"
    ) {
      small_image
    }
  }
}
`;

export const GETAVAILABLEPROVIDERS = gql`
  query GETAVAILABLEPROVIDERSFORJOB($ondemandId: ID) {
    get_available_providers_of_the_job(ondemandId: $ondemandId) {
      first_name
      last_name
      img_url
      email
      reviews{
        rating
      }
      description
      online
      _id
    }
  }
`;

export const GETPROVIDERSUMMARY = gql`
  query GETPROVIDERSUMMARY($_id: ID, $category_id: ID) {
    get_provider_summary(_id: $_id, category_id: $category_id) {
      _id
      proof_status
      first_name
      last_name
      img_url
      phone_no
      rating
      user_type
      description
      reviews {
        _id
        comments
        rating
        role
        model_ref
        ref_model
        is_approved
        user_id {
          _id
          first_name
          last_name
          img_url
          phone_no
          rating
          user_type
          description
          email
        }
      }
     
      overview {
        ondemand
        contract
        ondemandCategory
        contractCategory
        years
      }
      previous_work_done
      get_company_root_detail(root: true) {
        company_name
        company_website
        company_category
      }
      get_pro_profile_doc(user_id: $_id) {
        image_tag
        model_type
        get_sub_docs_images {
          small_image
        }
      }
      get_portfolioImage(root: true) {
        _id
        user_id
        portfolio
        portfolio_image
      }
      country_code
      location_code
      email
      address
      location
      password
      provider_subCategoryID
      professional_document_url
      personal_document_url
      subscription_active
      subscription_days(format_type: "days", root: "subscription_days")
      subscription_end_day
      
    }
  }
`;
export const GetChatMessageQuery = gql`
query GetChatMessageQuery(
  	$booking_id: ID
    $provider_id: ID
    $user_id:ID
    $role: Int
){
    get_message(
        booking_id: $booking_id
        provider_id: $provider_id
        user_id:$user_id
        role:$role
    ){
        message
        createdAt
        role
        user{
            name
            img_url
        }
        provider{
            name
            img_url
        }
        files{
            small_image
            large_image
            detail_img_id
            model_type
            doc_type
            user_id
            image_tag
        }
        file_type
    		msg_date
    		msg_time
    }
}
`;
export const GetChatInbox = gql`
query GetChatInbox($provider_id: ID,$per_page: Int,$page: Int) {
  get_chat_inbox(provider_id: $provider_id, per_page: $per_page, page: $page) {
    messages {
      message
      user_id
      provider_id
      msg_date
      booking_id
      msg_time
      unreadCount
      OnDemands
      {
        ondemand_ref
				get_category_names(root_parent:true){
          category_name
        }
      }
      user{
        name
        img_url
      }
      provider{
        name
        img_url
      }
      
    },
    paginationInfo {
      hasNextPage
      nextPage
    }
  }
}
`;
export const BIDDING_DETAILS = gql`
  query GetOnDemandBidingPagination(
    $page: Int
    $limit: Int
    $user_id: ID
    $location_code: String
    $sort: String
    $ondemand_id: ID
    $lat: Float
    $lng: Float
  ) {
    get_on_demand_biding_pagination(
      page: $page
      limit: $limit
      user_id: $user_id
      location_code: $location_code
      sort: $sort
      ondemand_id: $ondemand_id
    ) {
      pageInfo {
        totalDocs
      }
      data {
        _id
        provider_id
        budget(code: $location_code)
        add_to_shortlist
        biding_ref
        created_at
        user_id
        description
        experience
        response_time
        response_time_formate
        timeline
        timeline_type
        cover_letter
        service_fee
        total(code: $location_code)
        payment_option(code: $location_code)
        experience
        admin_fee(code: $location_code)

        get_on_demand_biding_all_files(root: true) {
          _id
          small_image
          image_tag
          images {
            small_image
            image_tag
          }
        }
        find_kilometer(lat: $lat, lng: $lng, root: true) {
          kilometre
        }
        provider_rating_by_category(root: true) {
          rating
        }
        get_user(root_parent: true) {
          _id
          img_url
          first_name
          user_type
          last_name
          _id
          rating
          online
          get_company_root_detail(root: true) {
            _id
            company_name
            get_company_images(root: true) {
              image_tag
              small_image
            }
            company_website
            company_category
            about_company
            provider_id
          }
          get_address {
            address
          }
        }
      }
    }
  }
`;
export const BID_ACTION = gql`
  mutation ManageOndemandJob(
    $booking_status: Int
    $ondemand_id: ID
    $biding_id: ID
    $location_code: String
    $stripe_token: String
    $payment_option: String
    $payment_type: String
    $role: Int
    $user_id: ID
    $provider_id: ID
    $publicToken: String
    $accountID: String
    $phone_number: String
  ) {
    manage_ondemand_booking(
      booking_status: $booking_status
      ondemand_id: $ondemand_id
      biding_id: $biding_id
      publicToken: $publicToken
      accountID: $accountID
      location_code: $location_code
      stripe_token: $stripe_token
      payment_option: $payment_option
      payment_type: $payment_type
      role: $role
      user_id: $user_id
      provider_id: $provider_id
      phone_number: $phone_number
    ) {
      created_at
      service_fee
      msg
      status
      booking_status
      milestone_status
    }
  }
`;
