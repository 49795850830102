import React, { useState, useEffect } from "react";
import "./project.css";
import { ONDEMAND_PROJECTS } from "../../../graphql/User/job";
import { client } from "../../../apollo";
import noimg from "../../../image/AdminHome/no_img.png";
import { Tooltip, Icon } from 'antd';
import { UPDATE_JOB } from "../../../graphql/User/job";
import { Alert_msg } from '../../Comman/alert_msg';

const OndemandProjects = ({
  status,
  id,
  booking_status
}) => {
  const [alljob, setalljob] = useState([]);
  useEffect(() => {
    getalljob();
  }, [id]);
  const getalljob = async () => {
    var providerdata = localStorage.getItem("user");
    var userid = JSON.parse(providerdata)._id;
    var locationcode = JSON.parse(providerdata).location_code;
    await client
      .query({
        query: ONDEMAND_PROJECTS,
        variables: {
          page: 1,
          location_code: locationcode,
          user_id: userid,
          role: 1,
          booking_status: Number(booking_status),
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        setalljob(result.data.get_ondemand_jobs_pagination.data);
      });
  };
  const archive_project = async(ondemand_id) => {
    var checkjob = await alljob.filter((data) => {
      return data._id === ondemand_id;
    });
    await client
      .query({
        query: UPDATE_JOB,
        variables: {
          ondemand_data: [
            {
              booking_status: 100,
              before_archive: Number(checkjob[0].booking_status)
            },
          ],
          _id: checkjob[0]._id
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        if (
          result.data.Update_OnDemand.status === "success"
        ) {
          Alert_msg({ msg: "Project moved to archive successfully", status: 'success' })
        }
        else
        {
          Alert_msg({ msg: result.data.Update_OnDemand.msg, status: 'failed' })
        }
        getalljob();
      });
  }
  const undo_archive_project = async(ondemand_id) => {
    var checkjob = await alljob.filter((data) => {
      return data._id === ondemand_id;
    });
    await client
      .query({
        query: UPDATE_JOB,
        variables: {
          ondemand_data: [
            {
              booking_status: Number(checkjob[0].before_archive)
            },
          ],
          _id: checkjob[0]._id
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        if (
          result.data.Update_OnDemand.status === "success"
        ) {
          Alert_msg({ msg: "Project moved to archive successfully", status: 'success' })
        }
        else
        {
          Alert_msg({ msg: result.data.Update_OnDemand.msg, status: 'failed' })
        }
        getalljob();
      });
  }
  const cancel_project = async(ondemand_id) => {
    var checkjob = await alljob.filter((data) => {
      return data._id === ondemand_id;
    });
    await client
      .query({
        query: UPDATE_JOB,
        variables: {
          ondemand_data: [
            {
              booking_status: 11
            },
          ],
          _id: checkjob[0]._id
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        if (
          result.data.Update_OnDemand.status === "success"
        ) {
          Alert_msg({ msg: "Project Cancelled successfully", status: 'success' })
        }
        else
        {
          Alert_msg({ msg: result.data.Update_OnDemand.msg, status: 'failed' })
        }
        getalljob();
      });
  }
  const viewjob = async (id) => {
    var checkjob = await alljob.filter((data) => {
      return data._id === id;
    });
    sessionStorage.setItem("biding_id", checkjob[0].biding_id);
    sessionStorage.setItem("provider_id", checkjob[0].provider_id);

    window.location.replace(`/jobaction/${checkjob[0]._id}`);
  };
  
  
  return (
    <div className="container">
      <div className="projectdiv">
        {alljob.length !== 0 ? (
          alljob.map((data, index) => (
            <div
              className="singleproject"
              key={index}
              id={data._id}
            >
              <img id={data._id} src={(data.get_ondemand_all_files[0]?.small_image)?data.get_ondemand_all_files[0]?.small_image:noimg} alt="" />
              <div onClick={()=>{viewjob(data._id)}} className="content_div" id={data._id}>
                <h5 id={data._id}>
                  <span className="bold_name" id={data._id}>
                    Category :
                  </span>{" "}
                  <span className="cat_name_label" id={data._id}>
                    {data.get_category_names.category_name}
                  </span>
                </h5>
                <p id={data._id} style={{ marginBottom: "0px" }}>
                  No Of Estimates :{" "}
                  <span className="cat_name_label">{data.biding_count}</span>
                </p>

                <span>
                  <b>{data.ondemand_ref}</b>
                </span>
                <br />
                <span>
                  <b>{data.booking_date}</b>
                </span>
              </div>
              <div className="align-items-center d-flex flex-column justify-content-center">
                <div>
                  {(data.booking_status==100)?
                    <Tooltip placement="right" title="Undo Archive">
                      <Icon onClick={()=>{undo_archive_project(data._id)}}  type="star" theme="filled" />
                    </Tooltip>
                  :
                    <Tooltip placement="right" title="Move to Archive">
                      <Icon onClick={()=>{archive_project(data._id)}} type="star" />
                    </Tooltip>
                  }
                </div>
                <div>
                  {(data.booking_status!=11)?
                  <Tooltip placement="right" title="Cancel Project">
                    <Icon onClick={()=>{cancel_project(data._id)}} type="delete" />
                  </Tooltip>:<></>}
                </div>                
              </div>
            </div>
          ))
        ) : (
          <div>
            <h6>No Project Found..</h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default OndemandProjects;
